import React from 'react';
import BoardDetail from 'template/BoardDetail';

export default function NoticeDetail() {
    const boardType = 'notice';
    return (
        <BoardDetail
            pageTitle="공지사항"
            visualProps={{
                category: 'notice',
                title: '공지사항',
                eng: 'DREAMS NOTICE'
            }}
            boardType={boardType}
        />
    );
}

const cardData = {
    0: [
        {
            title: '인공지능중심산업융합집적단지',
            tag: '거점·기술',
            subTitle: '1개 과제',
            desc: '인프라 구축, 일자리 창출, AI 반도체 실증 및 AI 융합 연구개발 지원 등',
            point: ''
        },
        {
            title: '디지털 혁신거점 조성지원',
            tag: '거점·기술',
            subTitle: '2개 과제',
            desc: '디지털 인프라 기반조성, 산학연 협력체계 구축, 네트워크 활성화 지원',
            point: ''
        }
    ],

    1: [
        {
            title: '정보소외지역SW교육지원강화',
            tag: '인적자원',
            subTitle: '4개 과제',
            desc: '디지털 소양을 갖춘 미래 인재 양성 기반 마련',
            point: '미래 인재 양성'
        },
        {
            title: 'ICT이노베이션스퀘어 조성',
            tag: '인적자원',
            subTitle: '5개 과제',
            desc: '기업 및 지역에서 필요로 하는 AI·SW 실무인재 양성을 통한 SW개발자 인력난 해소, 지역의 디지털전환 및 AI융합 촉진',
            point: '지역의 디지털전환 및 AI융합 촉진'
        }
    ],

    2: [
        {
            title: '스마트물류 기술 실증화',
            tag: '실증·보급',
            subTitle: '2개 과제',
            desc: '디지털 물류 플랫폼 고도화 및 품질수준 확보를 통한 최적화 구현',
            point: '디지털 물류 플랫폼 고도화'
        },
        {
            title: '농식품 분야 메타버스 기반 기술 실증지원',
            tag: '실증·보급',
            subTitle: '1개 과제',
            desc: '농식품 제조공정 중 푸드테크*에 특화된 디지털 핵심기술** 적용을 위한 플랫폼 구축 및 기술서비스 실증 지원',
            point: '푸드테크*에 특화된 디지털 핵심기술**'
        },
        {
            title: 'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용',
            tag: '실증·보급',
            subTitle: '1개 과제',
            desc: '빅데이터 인공지능 기술 융합을 통한 수산양식산업 현안문제 해결 및 지역 기반산업 디지털 혁신 촉진',
            point: '빅데이터 인공지능 기술 융합'
        },
        {
            title: '지역 디지털 기초체력 지원',
            tag: '실증·보급',
            subTitle: '1개 과제',
            desc: '지역 디지털 기업 경쟁력 강화',
            point: ''
        },
        {
            title: '제조업 AI융합 기반 조성',
            tag: '실증·보급',
            subTitle: '1개 과제',
            desc: '제조데이터 기반의 AI솔루션 개발·실증 등으로 제조업 현안해결 및 AI융합산업 육성 기반을 마련하여 지역 AI융합 생태계 조성',
            point: '지역 AI융합 생태계 조성'
        },
        {
            title: 'AI융합 지능형 농업 생태계 구축',
            tag: '실증·보급',
            subTitle: '1개 과제',
            desc: 'AI기반 지능형 농업기술의 융합·확산 기반 조성 및 경쟁력 강화를 위해 AI기반 농기계 자율작업 및 정밀농업 기술 개발·실증',
            point: 'AI기반 농기계 자율작업 및 정밀농업 기술 개발·실증'
        },
        {
            title: 'AI기반 중량화물이동체 물류플랫폼 실증',
            tag: '실증·보급',
            subTitle: '1개 과제',
            desc: '해운물류의 디지털전환을 위한 서비스플랫폼 개발과 지능형 선박의 핵심장치인 항해시스템의 통합 국산화 개발 후 실선박 검증',
            point: '해운물류의 디지털전환'
        }
    ]
};

const infographicData = {
    '2024년': [
        // 거점·기술
        {
            idx: 0,
            tag: '거점·기술',
            title: '인공지능중심산업융합집적단지',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-tech-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-tech-mo-2024.svg',
            alt: '인공지능중심산업융합집적단지'
        },
        {
            idx: 1,
            tag: '거점·기술',
            title: '디지털 혁신거점 조성지원',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-digital-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-digital-mo-2024.svg',
            alt: '디지털 혁신거점 조성지원'
        },

        // 인적자원
        {
            idx: 2,
            tag: '인적자원',
            title: '정보소외지역SW교육지원강화',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-edu-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-edu-mo-2024.svg',
            alt: '정보소외지역SW교육지원강화'
        },
        {
            idx: 3,
            tag: '인적자원',
            title: 'ICT이노베이션스퀘어 조성',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-innovation-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-innovation-mo-2024.svg',
            alt: 'ICT이노베이션스퀘어 조성'
        },
        // {
        //     idx: 10,
        //     tag: '인적자원',
        //     title: '지역산업 SW인재양성 기반조성',
        //     isHistory: true,
        //     pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-local-sw-2024.svg',
        //     mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-local-sw-mo-2024.svg',
        //     alt: '지역산업 SW인재양성 기반조성'
        // },

        // 실증확산
        {
            idx: 4,
            tag: '실증확산',
            title: '스마트물류 기술 실증화',
            isHistory: false,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-demonstration-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-demonstration-mo-2024.svg',
            alt: '스마트물류 기술 실증화'
        },
        {
            idx: 5,
            tag: '실증확산',
            title: '농식품 분야 메타버스 기반 기술 실증지원',
            isHistory: false,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-metaverse-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-metaverse-mo-2024.svg',
            alt: '농식품 분야 메타버스 기반 기술 실증지원'
        },
        {
            idx: 6,
            tag: '실증확산',
            title: 'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용',
            isHistory: false,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-bigdata-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-bigdata-mo-2024.svg',
            alt: 'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용'
        },
        {
            idx: 7,
            tag: '실증확산',
            title: '지역 디지털 기초체력 지원',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-promotion-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-promotion-mo-2024.svg',
            alt: '지역 디지털 기초체력 지원'
        },
        {
            idx: 8,
            tag: '실증확산',
            title: '제조업 AI융합 기반 조성',
            isHistory: false,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-industry-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-industry-mo-2024.svg',
            alt: '제조업 AI융합 기반 조성'
        },
        {
            idx: 9,
            tag: '실증확산',
            title: 'AI융합 지능형 농업 생태계 구축',
            isHistory: false,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-farm-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-farm-mo-2024.svg',
            alt: 'AI융합 지능형 농업 생태계 구축'
        },
        {
            idx: 10,
            tag: '실증확산',
            title: 'AI기반 중량화물이동체 물류플랫폼 실증',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-cargo-2024.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-cargo-mo-2024.svg',
            alt: 'AI기반 중량화물 이동체 물류플랫폼 실증'
        }

        // 지역 주도 기획
        // {
        //     idx: 11,
        //     tag: '지역 주도 기획',
        //     title: 'SW융합클러스터2.0',
        //     isHistory: true,
        //     pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-sw-2024.svg',
        //     mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-sw-mo-2024.svg',
        //     alt: 'SW융합클러스터2.0'
        // },
    ],
    '2023년': [
        // 거점·기술
        {
            idx: 0,
            tag: '거점·기술',
            title: '인공지능중심산업융합집적단지',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-tech-2023.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-tech-mo-2023.svg',
            alt: '인공지능 중심 산업융합 집적단지 조성사업'
        },
        {
            idx: 1,
            tag: '거점·기술',
            title: '디지털 혁신거점 조성지원',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-digital-2023.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-digital-mo-2023.svg',
            alt: '디지털 혁신거점 조성지원'
        },

        // 인적자원
        {
            idx: 2,
            tag: '인적자원',
            title: '정보소외지역SW교육지원강화',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-edu-2023.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-edu-mo-2023.svg',
            alt: '정보소외지역SW교육지원강화'
        },
        {
            idx: 3,
            tag: '인적자원',
            title: 'ICT이노베이션스퀘어 조성',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-innovation-2023.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-innovation-mo-2023.svg',
            alt: 'ICT이노베이션스퀘어 조성'
        },
        // {
        //     idx: 10,
        //     tag: '인력양성',
        //     title: '지역산업 SW인재양성 기반조성',
        //     isHistory: true,
        //     pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-local-sw-2023.svg',
        //     mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-local-sw-mo-2023.svg',
        //     alt: '지역산업 SW인재양성 기반조성'
        // },

        // 실증확산
        {
            idx: 4,
            tag: '실증확산',
            title: '스마트물류 기술 실증화',
            isHistory: false,
            pc: '',
            mo: '',
            alt: '스마트물류 기술 실증화'
        },
        {
            idx: 5,
            tag: '실증확산',
            title: '농식품 분야 메타버스 기반 기술 실증지원',
            isHistory: false,
            pc: '',
            mo: '',
            alt: '농식품 분야 메타버스 기반 기술 실증지원'
        },
        {
            idx: 6,
            tag: '실증확산',
            title: 'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용',
            isHistory: false,
            pc: '',
            mo: '',
            alt: 'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용'
        },
        {
            idx: 7,
            tag: '실증확산',
            title: '지역 디지털 기초체력 지원',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-promotion-2023.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-promotion-mo-2023.svg',
            alt: '지역 디지털 기초체력 지원'
        },
        {
            idx: 8,
            tag: '실증확산',
            title: '제조업 AI융합 기반 조성',
            isHistory: false,
            pc: '',
            mo: '',
            alt: '제조업 AI융합 기반 조성'
        },
        {
            idx: 9,
            tag: '실증확산',
            title: 'AI융합 지능형 농업 생태계 구축',
            isHistory: false,
            pc: '',
            mo: '',
            alt: 'AI융합 지능형 농업 생태계 구축'
        },
        {
            idx: 10,
            tag: '실증확산',
            title: 'AI기반 중량화물이동체 물류플랫폼 실증',
            isHistory: true,
            pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-cargo-2023.svg',
            mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-cargo-mo-2023.svg',
            alt: 'AI기반 중량화물 이동체 물류플랫폼 실증'
        }
        // {
        //     idx: 7,
        //     tag: '실증확산',
        //     title: 'AI기술실증 테스트베드 조성',
        //     isHistory: false,
        //     pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-test-2023.svg',
        //     mo: '',
        //     alt: 'AI기술실증 테스트베드 조성'
        // },

        // 지역 주도 기획
        // {
        //     idx: 11,
        //     tag: '지역 주도 기획',
        //     title: 'SW융합클러스터2.0',
        //     isHistory: true,
        //     pc: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-sw-2023.svg',
        //     mo: 'https://nipa-dreams.s3.ap-northeast-2.amazonaws.com/static/media/business/info-sw-mo-2023.svg',
        //     alt: 'SW융합클러스터2.0'
        // },
    ]
};

export { cardData, infographicData };

import React from 'react';
import Visual from 'components/Visual';
import { Box, Container, Text } from '@radix-ui/themes';
import BreadcrumbsBox from 'components/BreadcrumbsBox/index';
import PageTitle from 'components/PageTitle';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

const LocalDigitalIndustry = () => {
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';
    return (
        <>
            <Visual category="introduction" title="소개" eng="Dreams Introduction" />
            <section>
                <Container>
                    <BreadcrumbsBox />
                    <PageTitle title="지역 디지털 산업" />
                    <Box className="industry">
                        <h4 className={`title${isMobile ? '5' : '4'}`}>
                            <span className="color-primary">디지털 재창조</span>로<br />
                            <span className={`${isMobile ? '' : 'highlight'}`}>지방 신산업 혁신 역량 강화</span>
                        </h4>
                        <Text as="p" className="desc body1">
                            지역 특성을 고려한 디지털 혁신 환경 조성 및 성장체계 확립을 통해
                            {!isMobile ? <br className="pc-only" /> : null}
                            글로벌 수준의 지역 디지털 생태계 육성
                        </Text>

                        <Box className="life-elements">
                            <Box className="title-wrap">
                                <Text className="title title5">
                                    생태계
                                    <br />
                                    구성요소
                                </Text>
                            </Box>
                        </Box>

                        <Box className="img-box">
                            <img src={require('assets/images/pages/introduction/img-about-dreams-arrow.svg').default} alt="화살표 아이콘" />
                        </Box>

                        <ul className="items">
                            <li className="item item-tech">
                                <dl className="sub-title1">
                                    <dt className={`title${isMobile ? '5' : '4'}`}>거점·기술</dt>
                                    <dd>인공지능중심산업융합집적단지</dd>
                                    <dd>디지털 혁신거점 조성지원</dd>
                                </dl>
                            </li>
                            <li className="item item-resource">
                                <dl className="sub-title1">
                                    <dt className={`title${isMobile ? '5' : '4'}`}>인적자원</dt>
                                    <dd>정보소외지역SW교육지원강화</dd>
                                    <dd>ICT이노베이션스퀘어 조성</dd>
                                </dl>
                            </li>
                            <li className="item item-supply">
                                <dl className="sub-title1">
                                    <dt className={`title${isMobile ? '5' : '4'}`}>실증·보급</dt>
                                    <dd>스마트물류 기술 실증화</dd>
                                    <dd>농식품 분야 메타버스 기반 기술 실증지원</dd>
                                    <dd>AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용</dd>
                                    <dd>지역 디지털 기초체력 지원</dd>
                                    <dd>제조업 AI융합 기반 조성</dd>
                                    <dd>AI융합 지능형 농업 생태계 구축</dd>
                                    <dd>AI기반 중량화물이동체 물류플랫폼 실증</dd>
                                </dl>
                            </li>
                        </ul>
                    </Box>
                </Container>
            </section>
        </>
    );
};

export default LocalDigitalIndustry;

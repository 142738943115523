import React, { Suspense } from 'react';
import { Box, Text, Section } from '@radix-ui/themes';

import './style.scss';

export default function SolutionGuide1({ title, bg, device, agendaData, Components }) {
    const isMobile = device === 'mobile';

    return (
        <Section
            py={isMobile ? 'var(--space-4)' : 'var(--space-10)'}
            px={isMobile ? 'var(--space-4)' : 'var(--space-10)'}
            className={`solution-infographic-bg ${bg}`}
        >
            <Text as="p" mb={isMobile ? 'var(--space-2)' : 'var(--space-5)'} className={isMobile ? 'title7' : 'title5'}>
                {title}
            </Text>

            <Box className="solution-infographic-contents">
                {/* 아젠다 */}
                <Suspense fallback={<div></div>}>
                    {Components.map((Component, index) => (
                        <div id={agendaData[index].templateCode} key={index}>
                            <Component index={index} templateData={agendaData.map(data => data.templateData)[index]} />
                        </div>
                    ))}
                </Suspense>
            </Box>
        </Section>
    );
}

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@radix-ui/themes';
import BoardText from 'template/BoardText';
import { instance } from 'api/axios.instance';
import { selectDevice } from 'store/slices/common';

export default function VideoGallery() {
    const deviceType = useSelector(selectDevice);
    const boardType = 'promotion';
    const [galleryList, setGalleryList] = useState({ list: [], pagination: {} });
    const [loading, setLoading] = useState(true);
    const [fetchBody, setFetchBody] = useState({
        boardType,
        pageNo: 1,
        pageSize: 10,
        searchType: '',
        search: '',
        orderBy: 'post_no',
        inOrder: 'desc'
    });

    const tableHeader = [
        { label: 'NO', key: 'index', width: '80px' },
        { label: '제목', key: 'title', width: 'auto', left: true, className: 'has-detail', code: 'postNo' },
        { label: '작성자', key: 'writer', width: '108px' },
        { label: '작성일', key: 'createDate', width: '200px' },
        { label: '조회수', key: 'viewCount', width: '120px' }
    ];

    const fetchGalleryData = useCallback(async () => {
        const list = await instance('/post/list', {
            method: 'POST',
            data: fetchBody
        });
        setLoading(false);
        setGalleryList({ list: list.data.data.posts, pagination: list.data.data.pagination });
    }, [fetchBody]);

    useEffect(() => {
        fetchGalleryData();
    }, [fetchBody, fetchGalleryData]);

    return (
        <Box>
            <BoardText
                pageTitle="우수 영상관"
                visualProps={{
                    category: 'notice',
                    title: '우수 영상관',
                    eng: 'Video Gallery'
                }}
                tableHeader={tableHeader}
                tableBody={galleryList}
                setFetchBody={setFetchBody}
                loading={loading}
                boardType={boardType}
            />
        </Box>
    );
}

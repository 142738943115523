import { Flex, Separator } from '@radix-ui/themes';
import React from 'react';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

const Tap = ({ roleValue, color, title, firstSquare, firstText, secondSquare, secondText, icon }) => {
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';
    return (
        <div className="box">
            <div className={`tap ${color}`}>
                <p className="title6">{title}</p>
            </div>
            <div className="rectangular">
                <div className="icon">
                    {icon === 1 && (
                        <img src={require('assets/images/pages/policy/img-department-logo-01.svg').default} alt="과학기술 정보통신부" />
                    )}
                    {icon === 2 && (
                        <img src={require('assets/images/pages/policy/img-department-logo-02.svg').default} alt="정보통신 산업진흥원" />
                    )}
                    {icon === 3 && (
                        <img src={require('assets/images/pages/policy/img-department-logo-03.svg').default} alt="지역 사업 심의위원회" />
                    )}
                    {icon === 4 && (
                        <img src={require('assets/images/pages/policy/img-department-logo-04.svg').default} alt="지역 디지털 전략 컨설팅" />
                    )}
                    {icon === 5 && <img src={require('assets/images/pages/policy/img-department-logo-05.svg').default} alt="지차체" />}
                    {icon === 6 && (
                        <img
                            src={require('assets/images/pages/policy/img-department-logo-06.svg').default}
                            alt="지역거점 및 지역SW진흥기관"
                        />
                    )}
                </div>
                <div className="desc-wrap">
                    <Flex align="center" gap="2">
                        <div className={`role sub-title${isMobile ? '2' : '1'}`}>
                            <p>역</p>
                            <p>할</p>
                        </div>
                        <Separator orientation="vertical" mx="4" />
                        <p className={`body${isMobile ? '2' : '1'}`}>{roleValue}</p>
                    </Flex>
                    <Flex direction="column" gap={isMobile ? '3' : '1'}>
                        <Flex gap={isMobile ? '0' : '2'} direction={isMobile ? 'column' : ''} align={isMobile ? 'start' : 'center'}>
                            <div className="square">
                                <p className={`sub-title${isMobile ? '2' : '1'}`}>{firstSquare}</p>
                            </div>
                            <p className={`body${isMobile ? '2' : '1'}`}>{firstText}</p>
                        </Flex>
                        {secondSquare && (
                            <Flex gap={isMobile ? '0' : '2'} direction={isMobile ? 'column' : ''} align={isMobile ? 'start' : 'center'}>
                                <div className="square">
                                    <p className={`sub-title${isMobile ? '2' : '1'}`}>{secondSquare}</p>
                                </div>
                                <p className={`body${isMobile ? '2' : '1'}`}>{secondText}</p>
                            </Flex>
                        )}
                    </Flex>
                </div>
            </div>
        </div>
    );
};

export default Tap;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Flex, Text } from '@radix-ui/themes';
import Visual from 'components/Visual';
import BreadcrumbsBox from 'components/BreadcrumbsBox';
import PageTitle from 'components/PageTitle';
import BoardSearchBox from 'components/BoardSearchBox';
import ListType from 'components/BoardTextItems/ListType';
import Pagination from 'components/Pagination';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
const PAGE_SIZE = 10;

export default function BoardText({ pageTitle, visualProps, tableHeader, tableBody, setFetchBody, loading, boardType }) {
    const location = useLocation();
    const navigate = useNavigate();
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = pageNo => {
        const totalPages = tableBody?.pagination.totalPage;

        if (pageNo >= 1 && pageNo <= totalPages) {
            setCurrentPage(pageNo);
            setFetchBody(prevData => ({
                ...prevData,
                pageNo
            }));
        }
    };
    const handleCellClick = code => {
        navigate(`${location.pathname}/detail/${code}`);
    };
    const getSearchText = ({ type, text }) => {
        setFetchBody(prevData => ({
            ...prevData,
            searchType: type,
            search: text
        }));
    };

    return (
        <>
            <Visual category={visualProps.category} title={visualProps.title} eng={visualProps.eng} />
            <section>
                <Container>
                    <BreadcrumbsBox />
                    <PageTitle title={pageTitle} />

                    <Box>
                        <BoardSearchBox getSearchText={getSearchText} />
                        {loading ? null : tableBody?.pagination?.totalCount > 0 ? (
                            <>
                                <Text
                                    as="p"
                                    mt={isMobile ? 'var(--space-6)' : 'var(--space-9)'}
                                    mb="var(--space-3)"
                                    className={`font-basic ${isMobile ? 'body3' : 'body2'}`}
                                >
                                    전체 {tableBody?.pagination.totalCount?.toLocaleString() || 0} 건
                                </Text>

                                <ListType
                                    isMobile={isMobile}
                                    boardType={boardType}
                                    currentPage={currentPage}
                                    totalCount={tableBody?.pagination.totalCount}
                                    tableHeader={tableHeader}
                                    tableData={tableBody.list}
                                    onCellClick={handleCellClick}
                                />
                                <Flex justify="center" mt="var(--space-12)">
                                    <Pagination
                                        totalPosts={tableBody?.pagination.totalCount}
                                        postsPerPage={PAGE_SIZE}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                </Flex>
                            </>
                        ) : (
                            <Box py={!isMobile && 'var(--space-25)'} pt={isMobile && 'var(--space-16)'}>
                                <Flex direction="column" align="center" justify="center" gap="var(--space-5)" className="no-data">
                                    <img src={require('assets/images/icon/icon-nodata.svg').default} alt="데이터에러" />
                                    <Text as="p" color="gray" className="body1">
                                        데이터가 없습니다.
                                    </Text>
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Container>
            </section>
        </>
    );
}

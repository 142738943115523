import React from 'react';
import Visual from 'components/Visual';
import { Box, Container, Flex, Separator, Text } from '@radix-ui/themes';
import BreadcrumbsBox from 'components/BreadcrumbsBox';
import PageTitle from 'components/PageTitle';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

const ImportantPolicy = () => {
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';
    return (
        <>
            <Visual category="policy" title="정책" eng="Dreams Policy" />
            <section className="enforce">
                <Container>
                    <BreadcrumbsBox />
                    <PageTitle title="지역 디지털 경쟁력 강화" />
                    <Box>
                        <div className="area">
                            <div className="title-box">
                                <p className={`title${isMobile ? '5' : '4'}`}>추진배경</p>
                            </div>
                            <div className="space">
                                <div>
                                    <div className="wrap-cube">
                                        <p className={`title${isMobile ? '6' : '5'}`}>
                                            디지털은 국가 전반의 혁신뿐 아니라, <strong>지방균형발전</strong>에서도 핵심
                                        </p>
                                    </div>
                                    <div className="desc">
                                        <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                            지방 주력산업의 新 부가가치를 창출하는 핵심원동력이며, 청년인재가 선호하는 양질의 지방 일자리
                                            창출의 원천
                                        </Text>
                                    </div>
                                </div>
                                <Box mt={`var(--space-${isMobile ? '9' : '10'})`}>
                                    <div className="wrap-cube">
                                        <p className={`title${isMobile ? '6' : '5'}`}>과거 지방균형발전 정책은</p>
                                    </div>
                                    <div className="desc">
                                        <p className={`body${isMobile ? '2' : '1'}`}>
                                            SOC 등 하드웨어(H/W)적 인프라 확충 중심의 경로의존적 한계를 보여 왔으나, 한번 익숙해지기
                                            시작하면 {!isMobile && <br />}그 경로가 비효율적으로 변하더라도 의존하는 경향
                                        </p>
                                        <Text as="p" className={`body${isMobile ? '2' : '1'}`} mt={isMobile ? '' : '5'}>
                                            지방의 인구소멸, 지방산업 및 지방대학 위기 등이 심화되는 가운데, 지자체는 지방문제의 돌파구로
                                            디지털을 새롭게 인식* 중
                                        </Text>
                                        <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                            * 예: (핵심과제화) 광주AI융합직접단지 등, (조직개편) 부산‧대구‧경북은 실‧국 조직 신설
                                        </Text>
                                    </div>
                                </Box>
                            </div>
                        </div>
                        <div className="area local-state">
                            <div className="title-box">
                                <p className={`title${isMobile ? '5' : '4'}`}>지방의 현주소</p>
                            </div>
                            <div className="box-wrap">
                                <div className="local-state box primary">
                                    <img src={require('assets/images/pages/policy/img-enforce-card-01.svg').default} alt="성장동력" />
                                    <Text as="p" className={`title${isMobile ? '6' : '5'}`} mt="8">
                                        성장동력
                                    </Text>
                                    <Text as="p" className={`body${isMobile ? '2' : '1'}`} mt="4">
                                        지방 주력산업의 위기 가운데 디지털 신산업 거점*, 정부R&D 투자 등 혁신 인프라·자원은 수도권에
                                        집중되는 경향
                                    </Text>
                                    <p className="sub-title2">강남 테헤란로, 판교 테크노밸리, 상암 DMC 등</p>
                                </div>
                                <div className="local-state box sky">
                                    <img src={require('assets/images/pages/policy/img-enforce-card-02.svg').default} alt="인적자원" />
                                    <Text as="p" className={`title${isMobile ? '6' : '5'}`} mt="8">
                                        인적자원
                                    </Text>
                                    <Text as="p" className={`body${isMobile ? '2' : '1'}`} mt="4">
                                        디지털 전공자의 50.4%는 지방대학이 배출하나 지방에서는 양질의 디지털 일자리를 찾기 어려운 상황
                                    </Text>
                                    <p className="sub-title2">지방대학 출신 디지털 전공자의 비수도권 정착률은 40%에 불과</p>
                                </div>
                                <div className="local-state box blue">
                                    <img src={require('assets/images/pages/policy/img-enforce-card-03.svg').default} alt="사회격차" />
                                    <Text as="p" className={`title${isMobile ? '6' : '5'}`} mt="8">
                                        사회격차
                                    </Text>
                                    <Text as="p" className={`body${isMobile ? '2' : '1'}`} mt="4">
                                        노년층 거주 비율이 높은 군·면·읍 지방 중심으로 연령 간 디지털 활용능력 격차 심화
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <div className="area focus">
                            <div className="title-box">
                                <p className={`title${isMobile ? '5' : '4'}`}>정책의 주안점</p>
                            </div>
                            <div className="space">
                                <Box pb={isMobile ? '9' : 'var(--space-10)'}>
                                    <div className="wrap-cube">
                                        <p className={`title${isMobile ? '6' : '5'}`}>
                                            지방의 경쟁력 있는 <strong>디지털 생태계</strong> 성장 지원
                                        </p>
                                    </div>
                                    <Box className="wrap-box" mt={isMobile ? '4' : '6'}>
                                        <div className="present">
                                            <p className={`top sub-title${isMobile ? '2' : '1'}`}>현행</p>
                                            <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>분야별 디지털 활용에 초점</p>
                                            <Box py="var(--space-10)" className="bottom">
                                                <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                                    부문별 디지털 기술 활용에 초점을 맞춰 지원
                                                </Text>
                                            </Box>
                                        </div>
                                        <div className="arrow-right">
                                            <img
                                                src={require('assets/images/pages/policy/img-enforce-arrow-small.svg').default}
                                                alt="화살표"
                                            />
                                        </div>
                                        <div className="improvement">
                                            <p className={`top sub-title${isMobile ? '2' : '1'}`}>개선</p>
                                            <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>디지털 생태계의 성장에 초점</p>
                                            <Box py="var(--space-6)" px="var(--space-7)" className="bottom">
                                                <p className={`body${isMobile ? '2' : '1'}`}>
                                                    디지털 기업·인재의 성장·정착, 기술 고도화 등
                                                </p>
                                                <p className={`body${isMobile ? '2' : '1'}`}>디지털 생태계 조성에 중점</p>
                                            </Box>
                                        </div>
                                    </Box>
                                </Box>
                                <Box pb={isMobile ? '9' : 'var(--space-10)'}>
                                    <div className="wrap-cube">
                                        <p className={`title${isMobile ? '6' : '5'}`}>
                                            지방을 가장 잘 아는 지자체가 <strong>지방에 최적화된 프로젝트</strong> 구상
                                        </p>
                                    </div>
                                    <Box className="wrap-box" mt="6">
                                        <div className="present">
                                            <p className={`top sub-title${isMobile ? '2' : '1'}`}>현행</p>
                                            <div className="mid">
                                                <p className="title7">지방 여건에 최적화된</p>
                                                <p className="title7">프로젝트 단위 지원 부족</p>
                                            </div>
                                            <Box pt="var(--space-6)" pb="var(--space-9)" px="var(--space-7)" className="bottom field">
                                                <Text as="p" className={`body${isMobile ? '2' : '1'}`} mb="var(--space-10)">
                                                    중앙정부가 사업 기획‧공모 → 지자체는 수주경쟁
                                                </Text>
                                                <hr className="dashed" />
                                                <Text as="p" mt="var(--space-5)" className={`body${isMobile ? '2' : '1'}`}>
                                                    지역에 최적화된 사업기획 미흡
                                                </Text>
                                            </Box>
                                        </div>
                                        <div className="arrow-right">
                                            <img
                                                src={require('assets/images/pages/policy/img-enforce-arrow-small.svg').default}
                                                alt="화살표"
                                            />
                                        </div>
                                        <div className="improvement">
                                            <p className={`top sub-title${isMobile ? '2' : '1'}`}>개선</p>
                                            <div className="mid">
                                                <p className="title7">지자체가 주도하여 기획하고</p>
                                                <p className="title7">중앙정부는 체계적이고 과감하게 투자</p>
                                            </div>
                                            <Box py="var(--space-6)" px="var(--space-7)" className="bottom field">
                                                <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                                    &lt;지자체&gt; 지역 여건을 고려하여 우선순위‧목표
                                                </Text>
                                                <Text as="p" mb="var(--space-3)" className={`body${isMobile ? '2' : '1'}`}>
                                                    설정 및 사업기획
                                                </Text>
                                                <hr className="dashed" />
                                                <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                                    &lt;중앙&gt; 지자체 기획에 대한 지원체계 마련 및
                                                </Text>
                                                <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                                    타당성 높은 사업에 적극 투자
                                                </Text>
                                            </Box>
                                        </div>
                                    </Box>
                                </Box>
                                <Box pb={isMobile ? '0' : 'var(--space-10)'}>
                                    <div className="wrap-cube">
                                        <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                            <strong>디지털 고유 관점</strong>의 지방 정책 추진체계 확립
                                        </p>
                                    </div>
                                    <Box className="wrap-box" mt="6">
                                        <div className="present">
                                            <p className={`top sub-title${isMobile ? '2' : '1'}`}>현행</p>
                                            <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>부문별 관점의 지방 혁신</p>
                                            <Box pt="var(--space-6)" pb="var(--space-9)" px="var(--space-7)" className="bottom field">
                                                <div>
                                                    <Text as="p" className={`body${isMobile ? '2' : '1'}`}>
                                                        지역 내 디지털 혁신에 전문화된
                                                    </Text>
                                                    <Text as="p" mb="var(--space-4)" className={`body${isMobile ? '2' : '1'}`}>
                                                        공공기관의 위상·역량 미흡
                                                    </Text>
                                                </div>
                                                <hr className="dashed" />
                                                <Text as="p" mt="var(--space-5)" className={`body${isMobile ? '2' : '1'}`}>
                                                    지방 디지털 혁신이 부수적 아젠다로 논의됨
                                                </Text>
                                            </Box>
                                        </div>
                                        <div className="arrow-right">
                                            <img
                                                src={require('assets/images/pages/policy/img-enforce-arrow-small.svg').default}
                                                alt="화살표"
                                            />
                                        </div>
                                        <div className="improvement">
                                            <p className={`top sub-title${isMobile ? '2' : '1'}`}>개선</p>
                                            <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>디지털 관점의 지방 혁신기반 마련</p>
                                            <Box pt="var(--space-6)" pb="var(--space-9)" px="var(--space-7)" className="bottom field">
                                                <Text as="p" mb="var(--space-10)" className={`body${isMobile ? '2' : '1'}`}>
                                                    지역 내 디지털 전담 씽크탱크 육성
                                                </Text>
                                                <hr className="dashed" />
                                                <Text as="p" mt="var(--space-5)" className={`body${isMobile ? '2' : '1'}`}>
                                                    디지털 정책을 지역발전을 위한 핵심 아젠다로 논의
                                                </Text>
                                            </Box>
                                        </div>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </Box>
                </Container>
            </section>

            <section className="enforce-direction">
                <Container>
                    <Box className="direction-box">
                        <div className="box-img">
                            <div className="area-text">
                                <Text as="p" className={`title${isMobile ? '6' : '4'}`} mb="6">
                                    기본방향
                                </Text>
                                <Text as="p" mx={isMobile && 'var(--space-5)'} className={`title${isMobile ? '5' : '3'}`}>
                                    <strong>디지털 중심{isMobile ? <br /> : ''} 지방발전체계 (DREAM)</strong> 구축으로
                                    {isMobile ? '' : <br />} 지방시대 실현 및 국가 혁신동력 강화
                                </Text>
                            </div>
                        </div>
                        <Box className="goal-wrapper">
                            <Text as="p" className={`title${isMobile ? '5' : '4'}`} align="center">
                                목표 (~2027년)
                            </Text>
                            <Box className="wrap-goals">
                                <div className="box-goals">
                                    <Text as="p" className={`title${isMobile ? '7' : '6'}`}>
                                        지방 디지털 경제 총 생산액
                                    </Text>
                                    <div className="box-goals-em">
                                        <div className="em">30조원</div>
                                        {isMobile ? null : (
                                            <div className="money-icon">
                                                <img
                                                    src={require('assets/images/pages/policy/img-money.svg').default}
                                                    alt="지방 디지털 경제 총 생산액 장식 이미지"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p>
                                        2020년 10.5조원 <span className="arrow" /> 2027년 30조원
                                    </p>
                                </div>
                                {isMobile ? null : <Separator orientation="vertical" mx="var(--space-5)" />}
                                <div className="box-goals">
                                    <Text as="p" className={`title${isMobile ? '7' : '6'}`}>
                                        지방 디지털 인재 정착률
                                    </Text>
                                    <div className="box-goals-em">
                                        <div className="em">50%</div>
                                        {isMobile ? null : (
                                            <div className="rest-icon">
                                                <img
                                                    src={require('assets/images/pages/policy/img-rest-person.svg').default}
                                                    alt="지방 디지털 인재 정착률 장식 이미지"
                                                />
                                            </div>
                                        )}
                                        <p>
                                            2020년 40.1% <span className="arrow" /> 2027년 50%
                                        </p>
                                    </div>
                                </div>
                                {isMobile ? '' : <Separator orientation="vertical" mx="var(--space-5)" />}
                                <div className="box-goals">
                                    <Text as="p" className={`title${isMobile ? '7' : '6'}`}>
                                        지방 디지털 취약계층 정보화 수준
                                    </Text>
                                    <div className="box-goals-em">
                                        <div className="em">83%</div>
                                        {isMobile ? (
                                            ''
                                        ) : (
                                            <div className="arrow-up-icon">
                                                <img
                                                    src={require('assets/images/pages/policy/img-up-arrow-cloud.svg').default}
                                                    alt="지방 디지털 취약계층 정보화 수준 장식 이미지"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p>
                                        2020년 78.8% <span className="arrow" /> 2027년 83%
                                    </p>
                                </div>
                            </Box>
                        </Box>
                        <Box pb="var(--space-11)" className="key-task-wrapper">
                            <Text as="p" className={`title${isMobile ? '5' : '4'}`} align="center">
                                핵심과제
                            </Text>
                            <Flex className="key-task">
                                <div className="key-task-box primary">
                                    <div className="titles">
                                        <p className={`title${isMobile ? '7' : '6'}`}>지방 디지털</p>
                                        <Text as="p" className={`title${isMobile ? '5' : '4'}`} mt={isMobile ? '2' : ''}>
                                            혁신 추진기반 강화
                                        </Text>
                                    </div>
                                    <div className="key-task-box-flex">
                                        <div className="key-task-area">
                                            <div className="title-box">
                                                <p className={`title${isMobile ? '7' : '6'}`}>인적자원</p>
                                            </div>
                                            <div className="key-task-desc">
                                                <Text as="p" className={`title${isMobile ? '6' : '5'}`} align={isMobile ? 'center' : ''}>
                                                    지방혁신 선도 디지털 인재 양성체계 구축
                                                </Text>
                                                <ul>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>
                                                        지방 디지털 생태계 성장에 대한 지방대학 역할 강화
                                                    </li>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>
                                                        지방기업 수요의 현장형 디지털 인재 확충
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="key-task-area">
                                            <div className="title-box">
                                                <p className={`title${isMobile ? '7' : '6'}`}>지원 체계</p>
                                            </div>
                                            <div className="key-task-desc">
                                                <Text as="p" className={`title${isMobile ? '6' : '5'}`} align={isMobile ? 'center' : ''}>
                                                    지방 디지털 정책 추진체계 확립
                                                </Text>
                                                <ul>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>지방 디지털 정책 거버넌스 구축</li>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>
                                                        지방 디지털 사업 발굴 · 추진 절차 정립
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="key-task-box blue">
                                    <div className="titles">
                                        <p className={`title${isMobile ? '7' : '6'}`}>지방 디지털</p>
                                        <Text as="p" className={`title${isMobile ? '5' : '4'}`} mt={isMobile ? '2' : ''}>
                                            재창조 프로젝트 추진
                                        </Text>
                                    </div>
                                    <div className="key-task-box-flex">
                                        <div className="key-task-area is-arrow">
                                            <div className="title-box">
                                                <p className={`title${isMobile ? '7' : '6'}`}>거점 · 기술</p>
                                            </div>
                                            <div className="key-task-desc">
                                                <Text as="p" className={`title${isMobile ? '6' : '5'}`} align={isMobile ? 'center' : ''}>
                                                    지방 디지털 혁신 생태계 조성
                                                </Text>
                                                <ul>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>
                                                        지방 디지털 신산업 허브로 디지털 혁신지구 조성
                                                    </li>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>
                                                        지방 주도로 차별화된 디지털 기술기반 확보
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="key-task-area">
                                            <div className="title-box">
                                                <p className={`title${isMobile ? '7' : '6'}`}>실증 · 보급</p>
                                            </div>
                                            <div className="key-task-desc">
                                                <Text as="p" className={`title${isMobile ? '6' : '5'}`} align={isMobile ? 'center' : ''}>
                                                    디지털 기반 지방 경제 · 사회 혁신 지원
                                                </Text>
                                                <ul>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>지방 먹거리 산업의 디지털 전환 가속화</li>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>디지털로 지방 주민의 생활 · 안전 개선</li>
                                                    <li className={`body${isMobile ? '2' : '1'}`}>지방 주민의 디지털 활용능력 제고</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Flex>
                        </Box>
                    </Box>
                    <Separator size="4" mx="var(--space-5)" my="var(--space-20)" />
                </Container>
            </section>

            <section className="enforce">
                <Container>
                    <Box className="area focus">
                        <div className="title-box size-small">
                            <p className={`title${isMobile ? '5' : '4'}`}>추진과제</p>
                        </div>
                        <div className="space">
                            <section className="section-desc">
                                <p className={`title${isMobile ? '5' : '4'}`}>1. 지방 디지털 혁신 생태계 조성</p>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                지방 디지털 <strong>신산업의 허브(Hub)로, 디지털 혁신지구</strong> 조성
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className={`body${isMobile ? '2' : '1'}`}>
                                                지방 디지털 신산업 및 디지털 전환의 전략적 구심점 마련(’30년까지 5개 이상)
                                            </p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            디지털 기업이 1,000개 이상 집적된 「국가 디지털 혁신지구」를 ’30년까지 전국에 5개 이상
                                            조성(’23~)
                                        </li>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            디지털 기업·인재의 수요와 일치하는 지방 내 최적입지*에, 정부의 디지털 생태계 육성 관련 지원을
                                            고밀도로 집약
                                            <br />* 넓은부지, 도심외곽을 선 호하는 전통산업 입지와 차별화된 첨단산업 입지 경향 반영
                                        </li>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            지자체 주도로 디지털 신산업 성장 입지 설정 및 발전계획(10년 이상 단위) 수립 → 정부는 적합성 검토
                                            후 조성지역 지원
                                        </li>
                                    </ul>
                                    <Box
                                        pl={!isMobile && 'var(--space-9)'}
                                        className={`space ${isMobile ? 'type-column' : 'type-row'}`}
                                        mt="9"
                                    >
                                        <div className="wrap-box center one">
                                            <div className="common present">
                                                <p className={`top sub-title${isMobile ? '2' : '1'}`}>1단계</p>
                                                <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>초기기반 구축</p>
                                                <Box py="var(--space-7)" className="bottom">
                                                    <Text as="p" className="body1">
                                                        거점 특화 성장전략 마련 및
                                                    </Text>
                                                    <Text as="p" className="body1">
                                                        브랜드화 + 네트워크 형성
                                                    </Text>
                                                </Box>
                                            </div>
                                            <Box align="center">
                                                <img
                                                    src={require('assets/images/pages/policy/img-up-arrow-gray.svg').default}
                                                    alt="화살표"
                                                />
                                            </Box>
                                            <div className="investment">
                                                <Box py="var(--space-6)" className="wrap-investment-box">
                                                    <p className="sub-title1">중소규모 정부투자</p>
                                                    <p className="body2">(예 :디지털 혁신거점 시범사업 등)</p>
                                                </Box>
                                            </div>
                                        </div>
                                        {isMobile ? (
                                            ''
                                        ) : (
                                            <Box pt="var(--space-18)">
                                                <img src={require('assets/images/pages/policy/img-blue-arrow.svg').default} alt="화살표" />
                                            </Box>
                                        )}
                                        <div className="wrap-box center one">
                                            <div className="common present">
                                                <p className={`top sub-title${isMobile ? '2' : '1'}`}>2단계</p>
                                                <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>성장 가속화</p>
                                                <Box py="var(--space-7)" className="bottom">
                                                    <Text as="p" className="body1">
                                                        디지털 기업 보육,
                                                    </Text>
                                                    <Text as="p" className="body1">
                                                        산학연 협력 등에 집중 투자
                                                    </Text>
                                                </Box>
                                            </div>
                                            <Box align="center">
                                                <img
                                                    src={require('assets/images/pages/policy/img-up-arrow-gray.svg').default}
                                                    alt="화살표"
                                                />
                                            </Box>
                                            <div className="investment">
                                                <Box py="var(--space-6)" className="wrap-investment-box">
                                                    <p className="sub-title1">대규모 정부투자</p>
                                                    <p className="body2">
                                                        (예 : 대구 수성알파시티 조성사업, 광주 AI집적단지 2단계 조성사업 등)
                                                    </p>
                                                </Box>
                                            </div>
                                        </div>
                                        {isMobile ? (
                                            ''
                                        ) : (
                                            <Box pt="var(--space-18)">
                                                <img src={require('assets/images/pages/policy/img-blue-arrow.svg').default} alt="화살표" />
                                            </Box>
                                        )}
                                        <div className="wrap-box center one">
                                            <div className="common present">
                                                <p className={`top sub-title${isMobile ? '2' : '1'}`}>3단계</p>
                                                <p className={`mid ${isMobile ? 'sub-title2' : 'title7'}`}>민간이양, 글로벌화</p>
                                                <Box py="var(--space-7)" className="bottom">
                                                    <Text as="p" className="body1">
                                                        초격차R&D, 유니콘기업 육성 등
                                                    </Text>
                                                    <Text as="p" className="body1">
                                                        글로벌 경쟁력 확충
                                                    </Text>
                                                </Box>
                                            </div>
                                            <Box align="center">
                                                <img
                                                    src={require('assets/images/pages/policy/img-up-arrow-gray.svg').default}
                                                    alt="화살표"
                                                />
                                            </Box>
                                            <div className="investment">
                                                <Box py="var(--space-6)" className="wrap-investment-box">
                                                    <p className="sub-title1">민간주도 거버넌스로 전환</p>
                                                    <p className="body2">(예 : 판교 테크노밸리, 상암 DMC 등)</p>
                                                </Box>
                                            </div>
                                        </div>
                                    </Box>
                                </article>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                지방 주도로 <strong>차별화된 디지털 기술기반</strong> 확보
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className={`body${isMobile ? '2' : '1'}`}>
                                                방마다 차별화된 “기초 디지털 역량” 기반 마련 및 선도지역별 “4대 新 디지털 기술” 확보
                                            </p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            (기초 디지털 기반) 지역별 특성에 맞춰 SW역량을 차별화·강화할 수 있도록, 관련 사업 기획·추진에
                                            대한 지자체 자율성 확대{' '}
                                        </li>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            기존·신규 사업을 단계적으로 패키지화* → 지자체가 수립하는 ‘(가칭)지역디지털진흥계획’에 따라
                                            일괄지원(Turnkey) 및 자율 운영
                                            {isMobile ? '' : <br />} * 지역디지털기초체력지원, SW융합클러스터, 이노베이션스퀘어 등 연
                                            700억원 내·외{' '}
                                        </li>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            (사이버보안) 지방 디지털 전환에 따른 사이버 침해 위협에 대비하여 사이버 보안산업 거점으로 ‘지역
                                            거점 정보보호클러스터’ 조성(‘23~)
                                            <Text as="p" className="text-sub-title2">
                                                판교 정보보호클러스터를 모델로 ’23년 동남권(부산·울산·경남) 신규 구축
                                            </Text>
                                        </li>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            (4대 新 디지털 기술 기반) 글로벌 경쟁 중인 AI, 디지털 트윈, 블록체인, 메타버스 등 4대 신기술
                                            분야는 선도지역 중심으로 우선 추진{' '}
                                        </li>
                                    </ul>
                                </article>
                            </section>
                            <section className="section-desc">
                                <p className={`title${isMobile ? '5' : '4'}`}>2. 디지털 기반 지방 경제·사회 혁신</p>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                지방 먹거리 산업의 <strong>디지털 전환 가속화</strong>
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className={`body${isMobile ? '2' : '1'}`}>
                                                ’27년까지 지방에 SaaS 기반 디지털 서비스 강소기업 100개 이상 육성
                                            </p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className={`body${isMobile ? '2' : '1'}`}>
                                            (지방 특화 디지털 서비스 개발) XaaS[X(산업) + as a Service] 선도프로젝트 등을 통해 지방산업
                                            맞춤형 디지털 서비스 개발·실증
                                        </li>
                                        <li className="body1">
                                            (디지털 서비스 활용 촉진) 지방 제조현장, 농·축수산, 소상공인 등에 디지털 서비스 보급·확산 및
                                            활용역량 강화
                                            <Text as="p" className="text-sub-title2">
                                                산업단지 디지털 전환 대진단(산업부), 디지털 제조혁신·고도화 기업 육성(중기부), 스마트 농산물
                                                산지 유통센터(농림부) 전국상권 빅데이터 플랫폼 구축 등(중기부)
                                            </Text>
                                        </li>
                                    </ul>
                                </article>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                디지털로 지방 주민의 <strong>생활·안전 개선</strong>
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className="body1">‘27년까지 기초 지자체 단위로 디지털 SOC 300개 이상 신규 확충</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className="body1">
                                            (정주환경) AI·데이터 기반의 디지털 SOC 확충과 全국민 디지털 혜택 공유를 위한 "스마트빌리지"
                                            사업의 지원 규모* 확대 {isMobile ? '' : <br />}* ’22년 100억원 → ‘23년 632억원, 균특회계
                                            지역자율계정 전환을 통해 예산규모 6배 증가
                                        </li>
                                        <li className="body1">
                                            (SOC 안전) 지방 하수시설 가상화를 통한 도시침수* 등 선제적 예방 {isMobile ? '' : <br />}* 홍수
                                            예측모델 마련 및 IoT·CCTV 등 장비를 활용한 도시침수 예보시스템 구축
                                        </li>
                                        <li className="body1">
                                            (문화·의료·복지) 가상 관광상품, 메타버스 농장 개발·보급 및 AI, 원격기술 등으로 지방 의료·복지
                                            접근성 개선
                                        </li>
                                    </ul>
                                </article>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                지방 주민의 <strong>디지털 활용능력 제고</strong>
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className="body1">‘27년까지 농·어촌 주민의 디지털 활용능력을 일반국민의 83% 수준까지 향상</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className="body1">
                                            주민센터, 경로당 등 지방 밀착형 디지털 배움터(’22년 79.3만명)의 교육수단 확대* 및 지자체 운영
                                            자율성 강화 {isMobile ? '' : <br />}* 스마트폰 등으로 교육내용을 실습·반복 학습할 수 있는
                                            에뮬레이터앱 개발·개선
                                        </li>
                                    </ul>
                                </article>
                            </section>
                            <section className="section-desc">
                                <p className={`title${isMobile ? '5' : '4'}`}>3. 지방 혁신을 선도하는 디지털 인재 양성체계 구축</p>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                지방 디지털 생태계 성장에 대한 <strong>지방대학의 역할 강화</strong>
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className="body1">지방 디지털 생태계의 중추 역할을 수행할 디지털 선도 지방대학 육성</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className="body1">
                                            (지방대학 혁신 연계) 지자체-지방대 주도로 수립하는 RISE 운영계획 등과 과기정통부 디지털
                                            인재양성사업* 연계 추진(’24~) {isMobile ? '' : <br />}* (예) SW중심대학, 정보보호특성화대학,
                                            지역지능화혁신인재양성 등
                                        </li>
                                        <li className="body1">
                                            (계약정원제 안착) 인재수급이 시급한 디지털 분야에 SW중심대학, 디지털 인재 얼라이언스 등을
                                            중심으로 "계약정원제" 선도적 안착 {isMobile ? '' : <br />}- 기존 전략기술 분야 인재양성 사업에서
                                            계약정원제 시범도입 촉진
                                        </li>
                                        <li className="body1">
                                            (재직자 학위접근성 제고) 디지털 기업 집적지에 지방대학 학위과정을 위한 교육시설(예: 대구
                                            수성알파시티 "코드알파") 마련(’23~)
                                        </li>
                                    </ul>
                                </article>
                                <article>
                                    <div>
                                        <div className="wrap-cube">
                                            <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                                지방기업 수요의 <strong>현장형 디지털 인재 확충</strong>
                                            </p>
                                        </div>
                                        <div className="wrap-arrow">
                                            <span className="arrow" />
                                            <p className="body1">‘27년까지 비학위 디지털 고급·전문 교육과정의 60% 이상을 비수도권에 안배</p>
                                        </div>
                                    </div>
                                    <ul>
                                        <li className="body1">
                                            (이노베이션아카데미) 동료학습, 프로젝트 수행 중심의 혁신 교육과정인 이노베이션아카데미 권역별
                                            확산 추진(’23년 경산 신규 구축)
                                        </li>
                                        <li className="body1">
                                            (이노베이션스퀘어) 지방 산업현장 실무기반의 디지털 교육 강화 {isMobile ? '' : <br />}※ 서울,
                                            동남권, 동북권, 호남권, 충청권 5개 거점 센터 운영(’23년 7,310명 양성)
                                        </li>
                                        <li className="body1">
                                            (전문교육 및 미래인재) 기술 분야(AI, 메타버스)별 전문교육 확산, SW마이스터고* 신규 지정(경북)
                                            추진 및 AI영재학교** 신설 {isMobile ? '' : <br />}* 현재 4개교 → 5개교 / ** ’27년까지 광주·충북
                                            2개 학교 개교
                                        </li>
                                    </ul>
                                </article>
                            </section>
                            <section className="section-desc">
                                <p className={`title${isMobile ? '5' : '4'}`}>4. 지방 디지털 정책 추진체계 확립</p>
                                <article>
                                    <div className="wrap-cube">
                                        <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                            지방 디지털 정책 <strong>거버넌스 구축</strong>
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="body1">
                                            (디지털 씽크탱크 육성) 지방 SW산업진흥기관을 "지역 디지털 진흥기관"으로 개편하고 역할범위를
                                            디지털 혁신 전반으로 확대
                                        </li>
                                        <li className="body1">
                                            (의제화) 「(가칭)지방 디지털화 특별위원회*」(지방시대위원회 內) 신설 {isMobile ? '' : <br />}*
                                            (역할(안)) 지방 디지털 재창조프로젝트 범정부 지원 방안 논의, 분야별 정책‧사업 심의 등
                                        </li>
                                        <li className="body1">
                                            (제도기반) 지방 주도 디지털 정책 추진체계의 법적근거 마련("정보통신산업진흥법" 개정) 및 지역별
                                            "디지털 혁신 조례" 제정 추진
                                        </li>
                                    </ul>
                                </article>
                                <article>
                                    <div className="wrap-cube">
                                        <p className={`subtitle title${isMobile ? '6' : '5'}`}>
                                            지방 디지털 사업 <strong>발굴·추진</strong> 절차 정립
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="body1">
                                            지역별 특성‧여건 및 정책수요에 따른 핵심적인 지방 디지털 혁신 프로젝트의 기획‧제안 및 사업화
                                            절차를 체계화‧정례화
                                        </li>
                                    </ul>
                                    <Box py="var(--space-6)" px="var(--space-6)" ml={!isMobile && 'var(--space-9)'} className="gray-box">
                                        <Text as="p" className={isMobile ? 'body2' : 'body1'}>
                                            (중소·단기) “지방 자율형 디지털혁신 프로젝트”를 통해 사전 컨설팅, 공모‧평가를 거쳐 자유과제
                                            선정‧지원(’24~’26)
                                        </Text>
                                        <Text as="p" className={isMobile ? 'body2' : 'body1'}>
                                            (대형·중장기) 매년 정례화된 절차에 따라 범부처 차원의 신규사업 발굴‧추진
                                        </Text>
                                    </Box>
                                </article>
                            </section>
                        </div>
                    </Box>
                </Container>
            </section>

            <section className="digital-view">
                <Container>
                    <Box className="digital-view-box">
                        <Text as="p" className={`title${isMobile ? '5' : '4'}`} align="center">
                            지방 디지털 모습
                        </Text>
                        <Box mt="var(--space-15)">
                            <img
                                src={
                                    isMobile
                                        ? require('assets/images/pages/policy/img-digital-view-mo.svg').default
                                        : require('assets/images/pages/policy/img-digital-view.png')
                                }
                                alt={
                                    isMobile
                                        ? '지방 디지털 모습'
                                        : '지방 디지털 모습 - 연구자: 지방대학이 초일류 디지털기술 연구개발을 선도하고 있습니다. 직장인: 수도권 기업에 다니지만 지방의 워케이션 센터로 출퇴근하고 있어요. 지방경제도 살리고, 삶의 질이 좋아졌어요. 기업인: 디지털 기술이 제조업을 더욱 빠르게 성장시켜주고 있어요. 소방관: 디지털이 기후 변화를 실시간으로 확인해줘 덕분에 재난, 재해가 발생했을 때 신속 대응할 수 있었어요. 대학생: 지방대학 근처에 가고 싶은 일자리가 많아졌어요! 덕분에 지방대학 디지털 학과 선호도ㆍ경쟁률도 높아졌네요. 소상공인: 자랑스러운 우리 지방의 문화유산을 메타버스로 널리 알릴 수 있어 좋아요! 게다가 온라인 관광수입까지 늘어났어요. 주민: 지방 특색에 맞는 디지털 신산업의 발전 하나, 청년들이 머무르네요. 지방 경제의 미래에 기대됩니다! 농어민: 원격진료ㆍ교육으로 도심 수준의 혜택을 농촌에서도 누릴 수 있게 되었어요!'
                                }
                            />
                        </Box>

                        {isMobile ? (
                            <Flex direction="column" gap="4" mt="var(--space-12)" className="jobs">
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-01.svg').default} alt="연구자" />
                                    <Text as="p" className="body3">
                                        지방대학이 초일류 디지털기술 연구개발을 선도하고 있습니다!
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-02.svg').default} alt="직장인" />
                                    <Text as="p" className="body3">
                                        수도권 기업에 다니지만 지방의 워케이션 센터로 출퇴근 하고 있어요. 지방경제도 살리고, 삶의 질이
                                        좋아졌어요!
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-03.svg').default} alt="기업인" />
                                    <Text as="p" className="body3">
                                        디지털 기술이 제조업을 더욱 빠르게 성장시켜주고 있어요.
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-04.svg').default} alt="소방관" />
                                    <Text as="p" className="body3">
                                        디지털이 기후 변화를 실시간으로 확인해준 덕분에 재난· 재해가 발생했을 때 신속 대응할 수 있었어요.
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-05.svg').default} alt="대학생" />
                                    <Text as="p" className="body3">
                                        지방대학 근처에 가고 싶은 일자리가 많아졌어요! 덕분에 지방대학 디지털 학과 선호도·경쟁률도
                                        높아졌네요.
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-06.svg').default} alt="소상공인" />
                                    <Text as="p" className="body3">
                                        자랑스러운 우리 지방의 문화유산을 메타버스로 널리 알릴 수 있어 좋아요! 게다가 온라인 관광수입까지
                                        늘어났어요.
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-07.svg').default} alt="주민" />
                                    <Text as="p" className="body3">
                                        지방 특색에 맞는 디지털 신산업이 발전하니, 청년들이 머무르네요. 지방 경제의 미래게 기대됩니다!
                                    </Text>
                                </Flex>
                                <Flex gap="4" align="center">
                                    <img src={require('assets/images/pages/policy/img-digital-view-08.svg').default} alt="농어민" />
                                    <Text as="p" className="body3">
                                        원격진료·교육으로 도심 수준의 혜택을 농촌에서도 누릴 수 있게 되었어요!
                                    </Text>
                                </Flex>
                            </Flex>
                        ) : null}
                    </Box>
                </Container>
            </section>
        </>
    );
};

export default ImportantPolicy;

const regions = [
    { label: '서울', value: 'SU' },
    { label: '경기기타', value: 'GG' },
    { label: '경기', value: 'GG' },
    { label: '경기용인', value: 'YI' },
    { label: '경기고양', value: 'GY' },
    { label: '인천', value: 'IC' },
    { label: '대전', value: 'DJ' },
    { label: '세종', value: 'SJ' },
    { label: '충북', value: 'CB' },
    { label: '충남', value: 'CN' },
    { label: '광주', value: 'GJ' },
    { label: '전남', value: 'JN' },
    { label: '전북', value: 'JB' },
    { label: '대구', value: 'DG' },
    { label: '경북', value: 'GB' },
    { label: '부산', value: 'BS' },
    { label: '울산', value: 'US' },
    { label: '경남', value: 'GN' },
    { label: '강원', value: 'GW' },
    { label: '제주', value: 'JJ' }
];

export default regions;

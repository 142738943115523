import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
import { Box, Button, Container, Flex, Text } from '@radix-ui/themes';
import MobileHeader from './MobileHeader';

import './style.scss';

// 메뉴 데이터
export const menuList = [
    {
        id: 'intro',
        menu: '소개',
        link: '/introduction/localDigitalIndustry',
        title: '소개로 이동',
        img: require('assets/images/common/gnb-intro.png'),
        alt: '소개 아이콘',
        subMenus: [
            {
                menu: '지역 디지털 산업',
                link: '/introduction/localDigitalIndustry',
                title: '지역 디지털 산업으로 이동'
            },
            {
                menu: '지역 디지털 본부',
                link: '/introduction/localDigitalInnovation',
                title: '지역 디지털 본부으로 이동'
            },
            {
                menu: 'DREAMS 란?',
                link: '/introduction/aboutDreams',
                title: 'DREAMS 란으로 이동'
            }
        ]
    },
    {
        id: 'policy',
        menu: '정책',
        link: '/policy/importantPolicy',
        title: '정책으로 이동',
        img: require('assets/images/common/gnb-policy.png'),
        alt: '정책 아이콘',
        subMenus: [
            {
                menu: '중요정책',
                link: '/policy/importantPolicy',
                title: '지역 디지털 경쟁력 강화로 이동'
            },
            {
                menu: '추진체계',
                link: '/policy/propulsionSystem',
                title: '추진체계로 이동'
            }
        ]
    },
    {
        id: 'business',
        menu: '사업',
        link: '/business/survey',
        title: '사업으로 이동',
        img: require('assets/images/common/gnb-business.png'),
        alt: '사업 아이콘',
        subMenus: [
            // {
            //     menu: '지역별 사업 현황',
            //     link: '/business/regional',
            //     title: '지역별 사업 현황으로 이동'
            // },
            {
                menu: '지역 디지털 산업 생태계 실태조사',
                link: '/business/survey',
                title: '지역 디지털 산업 생태계 실태조사로 이동'
            },
            {
                menu: '사업별 성과 현황',
                link: '/business/performance',
                title: '사업별 성과 현황으로 이동'
            }
        ]
    },
    {
        id: 'solution',
        menu: '솔루션뱅크',
        link: '/solution/info',
        title: '솔루션뱅크로 이동',
        img: require('assets/images/common/gnb-solution.png'),
        alt: '솔루션뱅크 아이콘',
        subMenus: [
            {
                menu: '사업 소개',
                link: '/solution/info',
                title: '솔루션뱅크 사업 소개로 이동'
            },
            {
                menu: '프로젝트 목록',
                link: '/solution/projectList',
                title: '솔루션뱅크 프로젝트로 이동'
            }
        ]
    },
    {
        id: 'practices',
        menu: '우수사례',
        link: '/bestPractices/performanceReport',
        title: '우수사례로 이동',
        img: require('assets/images/common/gnb-best.png'),
        alt: '우수사례 아이콘',
        subMenus: [
            {
                menu: '성과보고회',
                link: '/bestPractices/performanceReport',
                title: '성과보고회로 이동'
            },
            {
                menu: '공모전',
                link: '/bestPractices/contest',
                title: '공모전으로 새창 이동'
            },
            {
                menu: '유공자',
                link: '/bestPractices/manOfMerit',
                title: '유공자로 새창 이동'
            }
        ]
    },
    {
        id: 'notice',
        menu: '공지사항',
        link: '/notice',
        title: '공지사항으로 이동',
        img: require('assets/images/common/gnb-best.png'),
        alt: '공지사항 아이콘',
        subMenus: [
            {
                menu: '성과보고회 공지사항',
                link: '/notice',
                title: '성과보고회 공지사항으로 이동'
            },
            {
                menu: '성과보고회 우수 영상관',
                link: '/videoGallery',
                title: '우수 영상관으로 이동'
            }
        ]
    }
];

const SubMenus = ({ isLinkActive, menu }) => {
    return (
        <>
            {menu.subMenus.map((subMenu, idx) => (
                <Link key={idx} to={subMenu.link} title={subMenu.title} className={`title7 ${isLinkActive(subMenu.link) ? 'active' : ''}`}>
                    {subMenu.menu}
                </Link>
            ))}
        </>
    );
};

const MenuDescription = ({ menu }) => {
    const getCaption = id => {
        switch (id) {
            case 'intro':
                return 'INTRODUCTION';
            case 'policy':
                return 'POLICY';
            case 'business':
                return 'BUSINESS';
            case 'solution':
                return 'SOLUTION BANK';
            default:
                return 'BEST PRACTICES';
        }
    };

    return (
        <Flex className="menu-desc">
            <Box className="text-wrap">
                <Text as="p" className="title5">
                    {menu.menu}
                </Text>
                <Text as="span" className="caption1">
                    DREAMS {getCaption(menu.id)}
                </Text>
                {/*<Text as="p" className="body1 contents">*/}
                {/*    균형발전 정책·사업 정보, 균형발전 우수사례*/}
                {/*</Text>*/}
            </Box>
            <Box className="img-wrap">
                <img src={menu.img} alt={menu.alt} />
            </Box>
        </Flex>
    );
};

export default function Header() {
    const location = useLocation();
    const device = useSelector(selectDevice);

    const [hoverMenu, setHoverMenu] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const currentPath = location.pathname;

    const toggleActive = () => {
        setIsActive(prevState => !prevState);
        setIsButtonClicked(prevState => !prevState);
    };

    /**
     * 사이트맵 버튼 클릭 시, isActive 상태 변경
     */

    useEffect(() => {
        const toggleClass = (selector, className, condition) => {
            const element = document.querySelector(selector);
            if (element) {
                element.classList.toggle(className, condition);
            }
        };

        toggleClass('header', 'open', isActive || isMenuOpen);
        toggleClass('.site-menu-wrap', 'open', isActive || isMenuOpen);
        toggleClass('header .extra', 'show', isActive);
    }, [isActive, isMenuOpen]);

    /**
     * 이벤트 핸들러
     * @param id
     */

    const handleMouseEnter = id => {
        if (!isButtonClicked) {
            setHoverMenu(id);
        }
    };
    const handleMouseLeave = () => {
        if (!isButtonClicked) {
            setHoverMenu(null);
        }
    };

    const handleButtonClick = () => {
        toggleActive();
        setIsMenuOpen(prevState => !prevState);
    };

    const isLinkActive = link => currentPath.startsWith(link);

    const isDepthMenuActive = menuLink => {
        const firstSegment = currentPath.split('/')[1];
        if (menuLink) {
            const result = menuLink.includes(firstSegment);
            if (menuLink === '/notice') {
                if (firstSegment === 'workshop') {
                    return true;
                }
            } else if (firstSegment !== '') return result;
        }
    };
    const isMenuActive = link => {
        const firstSegment = currentPath.split('/')[1];
        const result = link.includes(firstSegment);

        if (firstSegment === 'videoGallery' && link === '/notice') {
            return true;
        } else if (firstSegment !== '') return result;
    };

    return (
        <>
            {device === 'pc' ? (
                <header>
                    <Container>
                        <Flex align="center" className="header-inner">
                            <h1 className="logo">
                                <Link to="/" title="메인으로 이동">
                                    Dreams
                                </Link>
                            </h1>

                            <nav id="menu">
                                <Flex align="center">
                                    {menuList.map(menu => (
                                        <Box key={menu.id} onMouseEnter={() => handleMouseEnter(menu.id)} onMouseLeave={handleMouseLeave}>
                                            <Link
                                                to={menu.link}
                                                title={menu.title}
                                                className={`title6 ${isMenuActive(menu.link) ? 'active' : ''}`}
                                            >
                                                {menu.menu}
                                            </Link>
                                            {hoverMenu === menu.id && menu.subMenus && (
                                                <Box className="menu-wrap" onMouseLeave={handleMouseLeave}>
                                                    <Container>
                                                        <Flex gap="var(--space-10)" className="menu">
                                                            <Flex direction="column" className="menu-list">
                                                                <SubMenus isLinkActive={isLinkActive} menu={menu} />
                                                            </Flex>
                                                            <MenuDescription menu={menu} />
                                                        </Flex>
                                                    </Container>
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                </Flex>
                            </nav>

                            <Flex align="center" className="extra">
                                <Button
                                    type="text"
                                    size="1"
                                    className={'site-map ' + (isActive ? 'active' : '')}
                                    onClick={handleButtonClick}
                                >
                                    사이트맵
                                </Button>
                            </Flex>
                        </Flex>

                        <Box
                            className="site-menu-wrap"
                            style={{
                                display: isMenuOpen ? 'block' : 'none'
                            }}
                        >
                            <Container>
                                {isActive && (
                                    <Flex className="menu">
                                        {menuList.map(menu => (
                                            <Box key={menu.id} className={`depth-items ${isDepthMenuActive(menu.link) ? 'active' : ''}`}>
                                                {menu.subMenus && (
                                                    <Box className="sub-menu">
                                                        <SubMenus isLinkActive={isLinkActive} menu={menu} />
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Flex>
                                )}
                            </Container>
                        </Box>
                    </Container>
                </header>
            ) : (
                // mo
                <MobileHeader isActive={isActive} setIsActive={setIsActive} toggleActive={toggleActive} currentPath={currentPath} />
            )}
        </>
    );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, IconButton, Button } from '@radix-ui/themes';
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';
import { selectDevice } from 'store/slices/common';
import './style.scss';

export default function Pagination({ totalPosts, postsPerPage, handlePageChange, currentPage }) {
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';
    const totalPages = Math.ceil(totalPosts / postsPerPage);
    const pageLimit = 5;
    const startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
    const endPage = Math.min(totalPages, startPage + pageLimit - 1);

    // 페이지 번호 배열 계산
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }
    return (
        <Box className="pagination">
            <Flex>
                <Flex>
                    <IconButton title="처음으로" variant="solid" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                        <DoubleArrowLeftIcon width={18} height={18} />
                    </IconButton>
                    <IconButton title="이전" variant="solid" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <ChevronLeftIcon width={18} height={18} />
                    </IconButton>
                </Flex>
                <Flex className="pages">
                    {startPage > 1 && (
                        <Button size="1" className="rt-variant-text btn-sm" disabled>
                            ...
                        </Button>
                    )}
                    {pageNumbers.map(pageNum => (
                        <Button
                            key={pageNum}
                            size="1"
                            className={`rt-variant-text btn-sm ${currentPage === pageNum ? 'currentPage' : ''}`}
                            onClick={() => handlePageChange(pageNum)}
                        >
                            {pageNum}
                        </Button>
                    ))}
                    {endPage < totalPages && (
                        <Button size="1" className="rt-variant-text btn-sm" disabled>
                            ...
                        </Button>
                    )}
                </Flex>
                <Flex>
                    <IconButton
                        title="다음"
                        variant="solid"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <ChevronRightIcon width={18} height={18} />
                    </IconButton>
                    <IconButton
                        title="마지막으로"
                        variant="solid"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                    >
                        <DoubleArrowRightIcon width={18} height={18} />
                    </IconButton>
                </Flex>
            </Flex>
        </Box>
    );
}

import React from 'react';
import { Button, Box, Dialog, VisuallyHidden, Text } from '@radix-ui/themes';
import './style.scss';

const TermsDialog = ({ type, title }) => {
    return (
        <>
            <Dialog.Content>
                <Dialog.Title align="center">{title}</Dialog.Title>

                <VisuallyHidden>
                    <Dialog.Description>{title}</Dialog.Description>
                </VisuallyHidden>

                {type === 'term' && (
                    <>
                        <Box>
                            <Text as="p" mb="var(--space-1)" className="sub-title2">
                                주민등록 행정정보 공동이용 안내
                            </Text>
                            <Text as="p" className="font-basic body3 slim">
                                법률의 규정 또는 법령상 의무 준수를 위해 정보주체의 동의 없이 개인정보를 수집·이용 할 수 있습니다.
                            </Text>
                        </Box>
                        <ul className="terms-list">
                            <li className="font-basic sub-title3">1. 수집·이용 근거</li>
                            <li className="font-basic body3 slim">전자정부법 제36조제1항 및 지방세기본법 제129조</li>
                            <li className="font-basic sub-title3">2. 수집 목적</li>
                            <li className="font-basic body3 slim">
                                지방세법 제89조 제1항에 따른 개인지방소득세 신고자의 납세지 확정에 이용
                            </li>
                            <li className="font-basic body3 slim">지방세법 시행령 제28조의 3(세대의 기준)에 따른 1세대 확인에 이용</li>
                            <li className="font-basic sub-title3">3. 수집하는 개인정보의 항목</li>
                            <li className="font-basic body3 slim">
                                납세지 확정시 사용항목 : 주민등록번호, 성명, 주소변동 구분·내용·일자, 최종주소 변동일자
                            </li>
                            <li className="font-basic body3 slim">
                                1세대 확인시 사용항목 : 주민등록번호, 성명, 등록상태, 세대주와의관계, 전입일자
                            </li>
                            <li className="font-basic sub-title3">4. 공동이용 대상 이용기관의 명칭</li>
                            <li className="font-basic body3 slim">행정안전부, 전국 지방자치단체</li>
                            <li className="font-basic sub-title3">5. 보유 및 이용기간</li>
                            <li className="font-basic body3 slim">준영구</li>
                        </ul>
                    </>
                )}

                {type === 'personal' && (
                    <>
                        <Box>
                            <Text as="p" mb="var(--space-1)" className="sub-title2">
                                개인정보수집동의(필수)
                            </Text>
                            <Text as="p" className="font-basic body3 slim">
                                제1조(개인정보의 처리목적) ① "위택스"는 대국민 서비스 제공 및 민원처리, 소관업무 수행 등의 목적으로 필요에
                                의한 최소한으로 개인정보를 처리하고 있습니다. ② 개인정보는 다음의 목적을 위해 처리합니다. 처리한 개인정보는
                                다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
                            </Text>
                        </Box>
                        <ul className="terms-list">
                            <li className="font-basic sub-title3">1. 회원정보</li>
                            <li className="font-basic body3 slim">
                                회원가입, 회원제서비스 이용 및 제한적 본인 확인 절차에 따른 본인확인, 개인식별, 부정이용방지, 비인가
                                사용방지, 가입의사 확인, 만14세 미만 아동 개인정보 수집시 법정대리인 동의여부 확인, 추후 법정대리인
                                본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달 등
                            </li>
                            <li className="font-basic sub-title3">2. 부과 및 징수정보</li>
                            <li className="font-basic body3 slim">
                                지방세와 지방세외수입 전자수납·신고·조회를 위한 정보, 고지서 전자송달 및 알림 등
                            </li>
                            <li className="font-basic sub-title3">3. 민원사무 처리</li>
                            <li className="font-basic body3 slim">
                                민원신청서에 포함된 개인정보는 민원 처리의 목적으로만 민원접수기관 및 처리기관에서 이용
                            </li>
                            <li className="font-basic sub-title3">4. 과세정보 열람서비스 제공</li>
                            <li className="font-basic body3 slim">위택스 회원인 경우 지방세기본법에 의한 본인 납세자의 과세정보 열람</li>
                            <li className="font-basic sub-title3">5. 타 행정기관 시스템 연계정보 처리</li>
                            <li className="font-basic body3 slim">
                                다른 행정기관 등이 보유한 자료를 활용하여 민원인이 신고한 민원사무의 전자적 처리
                            </li>
                            <li className="font-basic sub-title3">6. 위택스 서비스 향상 및 정책평가</li>
                            <li className="font-basic body3 slim">
                                신규서비스 및 맞춤형 선제적 서비스 개발, 인구통계학적 특성에 따른 서비스 분석 및 서비스 유효성 및 이용 통계
                                확인 등
                            </li>

                            <li>
                                <Text as="p" my="var(--space-4)" className="font-basic body3 slim">
                                    제2조(처리하는 개인정보 항목) "위택스"가 처리하는 개인정보 항목은 다음 각호와 같습니다.
                                </Text>
                            </li>
                            <li className="font-basic sub-title3">1. 위택스 회원정보</li>
                            <li className="font-basic body3 slim">필수항목 : 성명, 전화번호, 주소</li>
                            <li className="font-basic body3 slim">선택항목 : 이메일주소</li>
                            <li className="font-basic sub-title3">2. 부과 및 징수정보</li>
                            <li className="font-basic body3 slim">
                                필수항목 : 성명, 전화번호, 주소, 소유자정보, 물건지정보, 신고자정보, 차량정보, 대행인정보, 계좌정보,
                                매수자정보
                            </li>

                            <li>
                                <Text as="p" my="var(--space-4)" className="font-basic body3 slim">
                                    제3조(개인정보의 처리 및 보유기간) "위택스"에서 처리하는 개인정보는 수집·이용 목적으로 명시한 범위
                                    내에서 처리하며, 개인정보보호법 및 관련 법령에서 정하는 보유 기간을 준용하여 이행하고 있습니다.
                                </Text>
                            </li>
                            <li className="font-basic sub-title3">1. 위택스 회원정보</li>
                            <li className="font-basic body3 slim">
                                수집근거 : 정보주체자동의, 지방세기본법 135조, 지방세기본법 시행령 제95조
                            </li>
                            <li className="font-basic body3 slim">보유기간 : 회원 탈퇴 의사 전까지(2년 경과시 재동의)</li>
                            <li className="font-basic sub-title3">2. 부과 및 징수정보</li>
                            <li className="font-basic body3 slim">
                                보유근거 : 지방세기본법 135조, 지방세기본법 시행령 제95조, 지방행정제재부과금법 시행령 제26조,
                                질서위반행위규제법 시행령 제15조
                            </li>
                            <li className="font-basic body3 slim">보유기간 : 준영구</li>

                            <li>
                                <Text as="p" mt="var(--space-4)" className="font-basic body3 slim">
                                    제4조(동의를 거부할 권리가 있다는 사실과 동의거부에 따른 불이익 내용) 이용자는 "위택스"에서 수집하는
                                    개인정보에 대해 동의를 거부할 권리가 있으며, 동의 거부시에는 회원가입, 신고 등의 민원 신청, 과세정보
                                    열람 등의 개인화 서비스 등이 제한됩니다.
                                </Text>
                            </li>
                        </ul>
                    </>
                )}

                {type === 'copyright' && (
                    <>
                        <Text as="p" className="font-basic body3 slim">
                            한국법무보호복지공단(이하”공단”)은 공단이 제공하는 인터넷 서비스(이하”서비스”)의 내용을 보호하고, 저작권 등
                            타인의 권리를 침해하지 않도록 하기 위하여 다음과 같이 공지하며, 다음에 명시되지 아니한 저작권 관련사항은
                            홈페이지 이용약관과 저작권법을 준용합니다.
                        </Text>
                        <ul className="terms-list">
                            <li className="font-basic sub-title3">게시물의 저작권 귀속 및 책임</li>
                            <li className="font-basic body3 slim">
                                공단 대표 홈페이지의 내용은 저작권법에 의한 보호를 받는 저작물로서, 이에 대한 무단 복제 및 배포를 원칙적으로
                                금합니다.
                            </li>
                            <li className="font-basic body3 slim">
                                이를 무단 복제·배포하는 경우 저작권법 제97조의5에 의한 저작재산권침해죄에 해당될 수 있습니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단이 저작권을 가지고 있는 게시물을 이용하는 경우에는 출처가 한국법무보호복지공단임을 반드시 명시하고 학술,
                                연구 등의 목적으로만 이용하여야 하며, 공단의 명시적 동의없이 게시물의 전부 또는 일부를 영리적 목적으로
                                이용하는 경우에는 민,형사상의 책임을 부담할 수 있습니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단 대표 홈페이지의 자료를 적법한 절차에 따라 다른 인터넷 사이트에 게재하는 경우에도 단순한 오류 정정
                                이외에 내용의 무단변경을 금지하며, 이를 위반할 때에는 형사 처벌을 받을 수 있습니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단은 회원(비회원 포함)이 서비스에 게재 또는 등록한 게시물에 대한 이용권, 서비스상의 게재권 등을 갖습니다.
                            </li>
                            <li className="font-basic body3 slim">
                                회원이 작성하여 서비스에 게재 또는 등록한 자료(컴퓨터프로그램 포함), 사진, 영상 등(이하 "게시물"이라
                                합니다)에 대한 저작권은 회원 본인에게 있는 것으로 간주되며, 게시물이 타인의 저작권 등 지적재산권을 침해하는
                                경우 그에 대한 책임은 회원 본인이 부담합니다.
                            </li>
                            <li className="font-basic sub-title3">홈페이지 사용자 및 제 3자의 저작권 보호</li>
                            <li className="font-basic body3 slim">
                                저작권자의 명시적 동의 없이 타인의 저작물(컴퓨터프로그램 포함. 이하 같음.)의 전부 또는 일부를 복제·배포·전송
                                등의 방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위임으로 복제·배포·전송 등의 방법으로 타인의
                                저작권을 침해하는 게시물을 서비스에 게재 또는 등록하여서는 안됩니다.
                            </li>
                            <li className="font-basic body3 slim">
                                타인의 저작물을 인용한 게시물인 경우 그 인용부분이 회원 개인의 의견을 뒷받침하기 위해 일부 인용된 것이
                                아니라 게시물의 핵심적인 부분을 구성하고 있다면 출처를 밝히더라도 저작권 침해에 해당됨으로 저작권자의 명시적
                                동의없이 이러한 게시물을 서비스에 게재 또는 등록하여서는 아니됩니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단의 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자 할 경우에는 반드시 해당회원의 명시적
                                동의를 얻은 뒤 사용하여야 합니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단은 회원이 서비스 이용과정에서 타인의 저작권, 상표권, 의장권 등 지적재산권을 침해하는 경우 어떠한 책임도
                                부담하지 않습니다.
                            </li>
                            <li className="font-basic sub-title3">게시물 중단 요청과 중단</li>
                            <li className="font-basic body3 slim">
                                공단의 서비스에 자신의 저작물이 게재되어 그 중단을 요청하려고 하는 회원(비회원 포함)은 당해 게시물의
                                저작권이 자신에게 있음을 입증하여야 하며, 입증된 게시물에 대하여 공단은 게재를 중단합니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단의 서비스에 게재된 게시물의 저작권을 주장하는 회원(비회원포함)은 그 주장이 허위임이 밝혀졌을 경우
                                민,형사상의 책임을 부담할 수 있습니다.
                            </li>
                            <li className="font-basic body3 slim">
                                공단의 서비스에 게재된 게시물이 타인의 저작권을 침해하였음이 명백할 경우 도는 그 게시물을 예고없이 삭제할 수
                                있습니다.
                            </li>
                        </ul>
                    </>
                )}

                {type === 'email' && (
                    <>
                        <Text as="p" className="font-basic body3 slim">
                            본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치 를 이용하여 무단으로 수집되는
                            것을 거부하며,이를 위반시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.
                        </Text>
                        <ul className="terms-list">
                            <li className="font-basic sub-title3">제50조의2 (전자우편주소의 무단 수집행위 등 금지)</li>
                            <li className="font-basic body3 slim">
                                누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이 인터넷 홈페이지에서 자동으로 전자우편주소를
                                수집 하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.
                            </li>
                            <li className="font-basic body3 slim">
                                누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매ㆍ유통하여서는 아니된다.
                            </li>
                            <li className="font-basic body3 slim">
                                누구든지 제1항 및 제2항의 규정에 의하여 수집ㆍ판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보 전송에
                                이용하여서는 아니된다.
                            </li>
                            <li className="font-basic sub-title3">
                                제74조 (벌칙) 다음 각호의 1에 해당하는 자는 1천만원 이하의 벌금에 처한다.
                            </li>
                            <li className="font-basic body3 slim">
                                제8조제4항의 규정을 위반하여 표시ㆍ판매 또는 판매할 목적으로 진열한 자
                            </li>
                            <li className="font-basic body3 slim">
                                제44조의7제1항제1호의 규정을 위반하여 음란한 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 배포ㆍ판매ㆍ임대하거나
                                공연히 전시한 자
                            </li>
                            <li className="font-basic body3 slim">제50조제6항의 규정을 위반하여 기술적 조치를 한 자</li>
                            <li className="font-basic body3 slim">
                                제50조의8의 규정을 위반하여 광고성 정보를 전송한 자 제50조의2의 규정을 위반하여 전자우편 주소를
                                수집ㆍ판매ㆍ유통 또는 정보전송에 이용한 자
                            </li>
                            <li className="font-basic body3 slim">제50조의8의 규정을 위반하여 광고성 정보를 전송한 자</li>
                            <li className="font-basic body3 slim">
                                제53조제4항을 위반하여 등록사항의 변경등록 또는 사업의 양도ㆍ양수 또는 합병ㆍ상속의 신고를 하지 아니한 자
                            </li>
                        </ul>
                    </>
                )}

                <Box className="fluid" mt="var(--space-9)">
                    <Dialog.Close>
                        <Button size="2" mr="var(--space-2)">
                            <Text as="p" className="sub-title2">
                                확인
                            </Text>
                        </Button>
                    </Dialog.Close>
                    <Dialog.Close>
                        <Button type="text" size="2" variant="outline">
                            <Text as="p" className="sub-title2">
                                취소
                            </Text>
                        </Button>
                    </Dialog.Close>
                </Box>
            </Dialog.Content>
        </>
    );
};

export default TermsDialog;

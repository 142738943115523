import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Card, Text, Flex } from '@radix-ui/themes';

import './style.scss';

export const CardItem = props => {
    const { pathname } = useLocation();

    return (
        <Card className={`board-item type-card ${props.className}`} data-tag={props.tag}>
            <Box py="var(--space-9)" px="var(--space-7)" className="card-header">
                <Text as="p" align="center" className="title7">
                    {props.title}
                </Text>
            </Box>
            <Box py="var(--space-5)" px="var(--space-7)" className="card-body">
                <Box className="sub-title">
                    <span className="tag">{props.tag}</span>
                    <Text as="p" className="title6">
                        {props.subTitle}
                    </Text>
                </Box>
                <Box className="card-contents">{props.children}</Box>
            </Box>
            <Box className="card-footer">
                <Link to={`${pathname}/detail?id=${props.index}`} state={props.title}>
                    <Flex align="center" py="var(--space-2)" pl="var(--space-6)" pr="var(--space-4)" gap="var(--space-8)">
                        <Text as="p" className="sub-title2">
                            VIEW
                        </Text>
                        <img src={require('assets/images/icon/icon-graph.svg').default} alt="그래프 아이콘" />
                    </Flex>
                </Link>
            </Box>
        </Card>
    );
};

export default CardItem;

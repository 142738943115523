import React from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, Dialog } from '@radix-ui/themes';

import '@radix-ui/themes/styles.css';

import ScrollToTop from 'hooks/useScrollTop';

/**
 * df-user token
 */
import 'assets/scss/tokens/df-user/df-user.scss';

import Skipnav from 'components/Skipnav/index';
import Header from 'components/Header';
import Footer from 'components/Footer';
import QuickMenu from 'components/QuickMenu';

import { instance } from 'api/axios.instance';
import RouteList from 'app/router';
import { generateRoutes } from 'app/router';
import { useDevice } from 'assets/js/device';
import { selectIsLogin } from './store/slices/users';
import { ErrorDialog } from 'components/Dialog/ErrorDialog';
import { selectIsErrorDialogOpen, setIsErrorDialogOpen, setErrorDialogStatus } from 'store/slices/common';

function App() {
    useDevice();
    const isLogin = useSelector(selectIsLogin);
    const isErrorDialogOpen = useSelector(selectIsErrorDialogOpen);
    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        const responseInterceptor = instance.interceptors.response.use(
            response => {
                console.debug(`response => ${response.config.url}`, response);
                return response;
            },
            err => {
                const { response } = err;
                console.debug(`err => ${err}`);
                const { status } = response ?? { status: 500 };

                if (status !== 200) {
                    dispatch(setIsErrorDialogOpen(true));
                    dispatch(
                        setErrorDialogStatus(
                            `[${status}] ${status === 500 ? response.data?.message : response.data?.error || response.data?.message}`
                        )
                    );
                    setTimeout(() => {
                        dispatch(setIsErrorDialogOpen(false));
                        dispatch(setErrorDialogStatus(''));
                    }, 3000);
                }
                return Promise.reject(response);
            }
        );
        return () => {
            instance.interceptors.response.eject(responseInterceptor);
        };
    }, [dispatch]);

    const currentRoute = RouteList.find(route => route.path === location.pathname);
    const fullScreen = currentRoute?.fullScreen;

    return (
        <div className="App">
            <Theme accentColor={'teal'} radius="none">
                <ScrollToTop>
                    <Skipnav />
                    {!fullScreen && <Header />}
                    <main id="contents">
                        <Routes>{generateRoutes(isLogin)}</Routes>
                    </main>
                    {!fullScreen && <Footer />}
                    <QuickMenu />
                </ScrollToTop>
                {isErrorDialogOpen && (
                    <Dialog.Root open={isErrorDialogOpen} onOpenChange={setIsErrorDialogOpen}>
                        <ErrorDialog />
                    </Dialog.Root>
                )}
            </Theme>
        </div>
    );
}

export default App;

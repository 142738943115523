import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Box, Heading, Flex, Text } from '@radix-ui/themes';

import Visual from 'components/Visual';
import BreadcrumbsBox from 'components/BreadcrumbsBox';
import PageTitle from 'components/PageTitle';
import Aside from 'components/Aside';
import SolutionGuide1 from 'template/SolutionInfo/guide1';
import SolutionGuide2 from 'template/SolutionInfo/guide2';

import { selectDevice, selectAsideMenu } from 'store/slices/common';
import { instance } from 'api/axios.instance';
import { useParams } from 'react-router-dom';

export default function ProjectListDetail() {
    const device = useSelector(selectDevice);
    const isMobile = device === 'mobile';
    const { id } = useParams();

    const [agendaData, setAgendaData] = useState([]);
    const [Components, setComponents] = useState([]);
    const selectedMenu = useSelector(selectAsideMenu);

    const handleRenderComponent = async componentNames => {
        const customOrder = ['TP001', 'TP003', 'TP007', 'TP005', 'TP009', 'TP011'];

        const sortedComponentNames = componentNames
            .sort((a, b) => {
                const indexA = customOrder.indexOf(a.templateCode);
                const indexB = customOrder.indexOf(b.templateCode);

                if (indexA === -1) return 1;
                if (indexB === -1) return -1;

                return indexA - indexB;
            })
            .map(data => data.componentName);

        for (const componentName of sortedComponentNames) {
            const { default: LoadedComponent } = await import(`components/template/${componentName}`);
            setComponents(prev => [...prev, LoadedComponent]);
        }
    };

    const fetchDocsById = async () => {
        const response = await instance(`/project/${id}`, {
            method: 'GET'
        });

        setComponents([]);

        if (response.data.status === 200) {
            handleRenderComponent(response.data.data.data);
            setAgendaData(response.data.data);
        }
    };

    useEffect(() => {
        fetchDocsById();
    }, []);
    return (
        <>
            <Visual category="solution" title="솔루션 뱅크" eng="Solution Bank" />
            <section>
                <Container className={isMobile && 'px-0'}>
                    <BreadcrumbsBox />
                    {isMobile ? (
                        <Box px="var(--space-5)">
                            <PageTitle title="프로젝트 목록" />
                        </Box>
                    ) : (
                        <PageTitle title="프로젝트 목록" />
                    )}
                    <Flex gap="var(--space-6)">
                        <Aside title={agendaData.title} agendaData={agendaData.data} />

                        <Box className="solution-infographic-wrap">
                            {/* title */}
                            <Flex
                                direction={isMobile && 'column'}
                                align={isMobile ? 'start' : 'center'}
                                py={isMobile ? 'var(--space-5)' : 'var(--space-10)'}
                                pl={isMobile ? 'var(--space-4)' : 'var(--space-15)'}
                                pr={isMobile && 'var(--space-4)'}
                                className="solution-infographic-title"
                            >
                                <Box>
                                    <Heading as={isMobile ? 'h6' : 'h3'} mb="var(--space-6)" className={isMobile ? 'title6' : 'title3'}>
                                        {agendaData.title}
                                    </Heading>
                                    <Text as="p" className={isMobile ? 'sub-title3' : 'sub-title1'}>
                                        {agendaData.description}
                                    </Text>
                                </Box>
                                {/* 임시 처리*/}
                                <img
                                    src={
                                        agendaData.thumbnail?.fileUrl || require('assets/images/pages/solution/icon-info-title.svg').default
                                    }
                                    alt="인포그래픽 정보 아이콘"
                                    style={{
                                        width: '446px',
                                        height: '360px'
                                    }}
                                />
                            </Flex>

                            {/* infographic - template */}
                            {/* {selectedMenu === 0 ? (
                                <SolutionGuide2 device={device} />
                            ) : ( */}
                            <SolutionGuide1
                                title="지역현안"
                                bg="gray"
                                device={device}
                                agendaData={agendaData.data}
                                Components={Components}
                            />
                            {/* )} */}
                        </Box>
                    </Flex>
                </Container>
            </section>
        </>
    );
}

import React from 'react';
import { Button, Box, Dialog } from '@radix-ui/themes';

const AuthEmailDialog = ({ type }) => {
    return (
        <>
            <Dialog.Content>
                <Dialog.Title align="center">{type === 'password' ? '비밀번호' : '아이디'} 전송 완료</Dialog.Title>

                <Dialog.Description size="2" align="center" color="gray" mt="var(--space-7)" className="body2">
                    등록된 이메일 주소로 {type === 'password' ? '새로운 비밀번호' : '아이디 정보'}가 전송되었습니다.
                </Dialog.Description>

                <Box className="fluid">
                    <Dialog.Close>
                        <Button size="2" mt="var(--space-9)">
                            확인
                        </Button>
                    </Dialog.Close>
                </Box>
            </Dialog.Content>
        </>
    );
};

export default AuthEmailDialog;

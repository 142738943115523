import { Link } from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';

const AccordionMenuItem = ({ menu, currentPath, setIsActive }) => (
    <Accordion.Item value={menu.id}>
        <Accordion.Trigger className="title7">
            {menu.menu}
            <span>버튼</span>
        </Accordion.Trigger>
        <Accordion.Content className="gnb-items">
            {menu.subMenus.map((subMenu, idx) => (
                <Link
                    onClick={() => setIsActive(false)}
                    key={idx}
                    to={subMenu.link}
                    className={`body2 ${currentPath === subMenu.link ? 'active' : ''}`}
                    title={subMenu.title}
                >
                    {subMenu.menu}
                    {subMenu.icon && <span className="out-link">새창이동</span>}
                </Link>
            ))}
        </Accordion.Content>
    </Accordion.Item>
);

export default AccordionMenuItem;

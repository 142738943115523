import React from 'react';
import { Box, Flex, Text } from '@radix-ui/themes';
export default function NoData() {
    return (
        <Box height="100%">
            <Flex direction="column" align="center" justify="center" gap="var(--space-5)" className="no-data" height="100%">
                <img src={require('assets/images/icon/icon-nodata.svg').default} alt="데이터 없음" />
                <Text as="p" color="gray" className="body1">
                    데이터가 없습니다.
                </Text>
            </Flex>
        </Box>
    );
}

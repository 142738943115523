import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Text } from '@radix-ui/themes';

import { useSelector, useDispatch } from 'react-redux';
import { selectDevice, setSelectAsideMenu } from 'store/slices/common';

import './style.scss';

export default function Aside({ title, agendaData }) {
    const dispatch = useDispatch();
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';

    const [selectIdx, setSelectIdx] = useState(0);
    const handleSelect = (i, menu) => {
        setSelectIdx(i);
        scrollToElement(`#${menu?.templateCode}`);
        dispatch(setSelectAsideMenu(i));
    };

    const scrollToElement = element => {
        const section = document.querySelector(element);
        if (!section) return;

        const yOffset = 0;
        const y = section.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <>
            {!isMobile && (
                <Flex direction="column" className="aside-wrap">
                    <Box py="var(--space-6)" px="var(--space-6)" className="aside-title">
                        <Text as="p">{title}</Text>
                    </Box>

                    <Box py="var(--space-7)" px="var(--space-6)">
                        <ul className="aside-menu">
                            {agendaData?.map((el, i) => (
                                <li key={i} className="aside-menu-item">
                                    <Link
                                        title={el.templateName}
                                        className={selectIdx === i ? 'body1 active' : 'body1'}
                                        onClick={() => handleSelect(i, el)}
                                    >
                                        {el.templateName}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Box>
                </Flex>
            )}
        </>
    );
}

import React from 'react';
import { Container } from '@radix-ui/themes';

import Visual from 'components/Visual';
import BreadcrumbsBox from 'components/BreadcrumbsBox';
import PageTitle from 'components/PageTitle';
import SortByYear from 'components/SortByYear';
import AwardTemplate from 'template/Award';

export default function Contest() {
    return (
        <>
            <Visual category="practices" title="우수사례" eng="Best Practices" />
            <section className="best-practices">
                <Container>
                    <BreadcrumbsBox />
                    <PageTitle title="유공자" />
                    <SortByYear path="award" />
                    <AwardTemplate type="merit" />
                </Container>
            </section>
        </>
    );
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'api/axios.instance';

/**
 * 인증 메일 전송
 * @param {string} email - 이메일 주소
 * @returns {Promise}
 */
export const sendAuthEmailUser = async email => {
    try {
        const response = await instance.post(`/auth/email/send`, { email });
        return response.data;
    } catch (error) {
        console.debug('error : ', error);
        return error;
    }
};

/**
 * 인증 메일 인증
 * @param email
 * @param authNumber
 * @returns {Promise<any>}
 */
export const confirmAuthEmailCode = async ({ email, authNumber }) => {
    try {
        const response = await instance.post(`/auth/email`, { email, authNumber });
        return response.data;
    } catch (error) {
        console.debug('error : ', error);
        return error;
    }
};

/**
 * 회원가입
 * @param region
 * @param email
 * @returns {Promise<any>}
 */
export const registerUser = async ({ region, email }) => {
    try {
        const response = await instance.post('/user/register', {
            region,
            email
        });
        return response;
    } catch (error) {
        console.debug('error : ', error);
        return error;
    }
};

/**
 * 로그인
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const loginUser = createAsyncThunk('users/login/post', async ({ id, password }, { rejectWithValue }) => {
    try {
        const response = await instance.post('/auth/login', {
            id,
            pw: password
        });
        return response.data;
    } catch (error) {
        console.debug('error : ', error);
        return rejectWithValue(error.response.data);
    }
});

/**
 * 로그아웃
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const logoutUser = createAsyncThunk('/users/logout/get', async (_, { rejectWithValue }) => {
    try {
        const response = await instance.get('/auth/logout');
        return true;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

/**
 * 비밀번호 찾기
 * @param email
 * @returns {Promise<*>}
 */
export const findUserPassword = async email => {
    try {
        const response = await instance.post(`/user/find/pw`, { email });
        return response;
    } catch (error) {
        console.debug('error : ', error);
        return error;
    }
};

/**
 * 아이디 찾기
 * @param email
 * @returns {Promise<*>}
 */
export const findUserId = async email => {
    try {
        const response = await instance.post(`/user/find/id`, { email });
        return response.data;
    } catch (error) {
        console.debug('error : ', error);
        return error;
    }
};

const initialState = {
    isLoading: false,
    isLogin: false, // 로그인 여부
    userDetails: {
        region: null,
        userId: null,
        type: null
    },
    error: null,
    stopUserReason: null // 사용 정지 유저 사유
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setType: (state, action) => {
            state.userDetails.type = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            // 로그인
            .addCase(loginUser.pending, state => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                const { status, data } = action.payload;
                // 미승인 시
                if (status === 400) {
                    state.isLogin = false;
                    if (data.status === '반려' || data.status === '미사용' || data.status === '사용불가') {
                        state.error = 'accountSuspended';
                        state.stopUserReason = data.message;
                    }
                    // state.error = 'invalidCredentials';
                    state.userDetails = {
                        region: null,
                        userId: null,
                        type: null
                    };
                }
                // 성공
                if (status === 200) {
                    const { id, type, region } = action.payload.data;
                    state.isLoading = false;
                    state.isLogin = true;
                    state.userDetails.userId = id;
                    state.userDetails.region = region;
                    state.userDetails.type = type;
                    state.error = null;
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = 'invalidCredentials'; // 로그인 실패
                // state.error = 'accountSuspended'; // 계정 정지
            })
            // 로그아웃
            .addCase(logoutUser.fulfilled, state => {
                state.userDetails = { region: null, userId: null, type: null };
                state.isLoading = false;
                state.isLogin = false;
                state.error = null;
            })
            .addCase(logoutUser.rejected, state => {
                state.userDetails = { region: null, userId: null, type: null };
                state.isLoading = false;
                state.isLogin = false;
                state.error = null;
            });
    }
});

export const { setType, setError } = userSlice.actions;

export const selectIsLogin = state => state.users.isLogin;
export const userInfo = state => state.users.userDetails;
export const selectType = state => state.users.userDetails.type;
export const selectRegion = state => state.users.userDetails.region;
export const selectErrorInfo = state => state.users.error;
export const selectStopReason = state => state.users.stopUserReason;

export default userSlice;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Flex, Text, Grid } from '@radix-ui/themes';
import Visual from 'components/Visual';
import BreadcrumbsBox from 'components/BreadcrumbsBox';
import PageTitle from 'components/PageTitle';
import BoardSearchBox from 'components/BoardSearchBox';
import Pagination from 'components/Pagination';
import { ImageTileItem } from 'components/BoardItem';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
import './style.scss';
const PAGE_SIZE = 12;

export default function BoardImage({ pageTitle, visualProps, projectRequestList, setFetchBody, loading }) {
    const location = useLocation();
    const navigate = useNavigate();
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = pageNo => {
        const totalPages = projectRequestList?.pagination.totalPage;

        if (pageNo >= 1 && pageNo <= totalPages) {
            setCurrentPage(pageNo);
            setFetchBody(prevData => ({
                ...prevData,
                pageNo
            }));
        }
    };
    const getSearchText = ({ type, text }) => {
        setFetchBody(prevData => ({
            ...prevData,
            searchType: type,
            search: text
        }));
    };

    return (
        <>
            <Visual category={visualProps.category} title={visualProps.title} eng={visualProps.eng} />
            <section className="board-image-wrap">
                {isMobile && (
                    <>
                        <BreadcrumbsBox />
                        <PageTitle title={pageTitle} />
                    </>
                )}
                <Container>
                    {!isMobile && (
                        <>
                            <BreadcrumbsBox />
                            <PageTitle title={pageTitle} />
                        </>
                    )}

                    <Box>
                        <BoardSearchBox getSearchText={getSearchText} />
                        {loading ? null : projectRequestList?.pagination.totalCount > 0 ? (
                            <>
                                <Text as="p" mt={isMobile ? 'var(--space-9)' : 'var(--space-3)'} className="body2 font-basic">
                                    전체 {projectRequestList?.pagination.totalCount?.toLocaleString()}
                                </Text>

                                <Grid
                                    columns={isMobile ? '1' : '4'}
                                    gap="var(--space-6)"
                                    pt={isMobile ? 'var(--space-5)' : 'var(--space-9)'}
                                    className="board-image-items"
                                >
                                    <ImageTileItem data={projectRequestList.list} />
                                </Grid>

                                <Flex justify="center" mt="var(--space-12)">
                                    <Pagination
                                        totalPosts={projectRequestList?.pagination.totalCount}
                                        postsPerPage={PAGE_SIZE}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                </Flex>
                            </>
                        ) : (
                            <Box py={!isMobile && 'var(--space-25)'} pt={isMobile && 'var(--space-16)'}>
                                <Flex direction="column" align="center" justify="center" gap="var(--space-5)" className="no-data">
                                    <img src={require('assets/images/icon/icon-nodata.svg').default} alt="데이터에러" />
                                    <Text as="p" color="gray" className="body1">
                                        데이터가 없습니다.
                                    </Text>
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Container>
            </section>
        </>
    );
}

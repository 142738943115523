import React from 'react';
import { Box, Container, Flex, Heading, Text } from '@radix-ui/themes';
import Visual from 'components/Visual';
import BreadcrumbsBox from 'components/BreadcrumbsBox/index';
import { days, timeTable } from './data';
import Report from 'template/Report';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

export default function PerformanceReport() {
    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';

    return (
        <>
            <Visual category="practices" title="우수사례" eng="Best Practices" />
            <section>
                {!isMobile && (
                    <Container className="schedule">
                        <BreadcrumbsBox />
                        <Report days={days} timeTable={timeTable} isMobile={isMobile} />
                    </Container>
                )}
                {isMobile && (
                    <Box className="schedule">
                        <Report days={days} timeTable={timeTable} isMobile={isMobile} />
                    </Box>
                )}
            </section>
        </>
    );
}

import React, { useState } from 'react';
import { Flex, IconButton, TextField, Tooltip } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

import './style.scss';

export default function BoardSearchBox({ getSearchText }) {
    const [searchText, setSearchText] = useState('');
    const handleSearch = () => {
        getSearchText({ text: searchText });
    };

    return (
        <Flex align="center" justify="end" gap="var(--space-3)" className="board-search-box">
            <TextField.Root
                placeholder="검색어를 입력해 주세요."
                color="gray"
                title="내용 입력 창"
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                    if (e.key === 'Enter') handleSearch();
                }}
            />
            <IconButton className="rt-variant-text" onClick={handleSearch}>
                {searchText.length > 0 || searchText.length === null ? (
                    <MagnifyingGlassIcon width={36} height={36} />
                ) : (
                    <Tooltip content="검색어를 입력해 주세요.">
                        <MagnifyingGlassIcon width={36} height={36} />
                    </Tooltip>
                )}
            </IconButton>
        </Flex>
    );
}

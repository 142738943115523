import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import BoardText from 'template/BoardText';
import { Box } from '@radix-ui/themes';
import { instance } from 'api/axios.instance';

export default function Notice() {
    const boardType = 'notice';
    const [noticeList, setNoticeList] = useState({ list: [], pagination: {} });
    const [loading, setLoading] = useState(true);
    const [fetchBody, setFetchBody] = useState({
        boardType,
        pageNo: 1,
        pageSize: 10,
        searchType: '',
        search: '',
        orderBy: 'post_no',
        inOrder: 'desc'
    });

    const tableHeader = [
        { label: 'NO', key: 'index', width: '80px' },
        { label: '제목', key: 'title', width: 'auto', left: true, className: 'has-detail', code: 'postNo' },
        { label: '작성자', key: 'writer', width: '108px' },
        { label: '작성일', key: 'createDate', width: '200px' },
        { label: '조회수', key: 'viewCount', width: '120px' }
    ];

    const fetchNoticeList = useCallback(async () => {
        const response = await instance('/post/list', {
            method: 'POST',
            data: fetchBody
        });
        setLoading(false);
        setNoticeList({ pagination: response.data.data.pagination, list: response.data.data.posts });
    }, [fetchBody]);

    useEffect(() => {
        fetchNoticeList();
    }, [fetchBody, fetchNoticeList]);
    return (
        <Box>
            <BoardText
                pageTitle="공지사항"
                visualProps={{
                    category: 'notice',
                    title: '공지사항',
                    eng: 'Dreams Notice'
                }}
                tableHeader={tableHeader}
                tableBody={noticeList}
                setFetchBody={setFetchBody}
                loading={loading}
                boardType={boardType}
            />
        </Box>
    );
}

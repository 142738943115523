import React, { useState } from 'react';
import { Flex, Text, TextField, IconButton } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';

import { useDispatch, useSelector } from 'react-redux';
import { selectErrorInfo, setError } from '../../store/slices/users';

import './style.scss';

// import ErrorIcon from '../../assets/images/icon/icon-error.svg';
// import ResetIcon from '../../assets/images/icon/icon-reset.svg';
//
// const IMAGES = {
//     error: { src: ErrorIcon, alt: '에러아이콘' },
//     reset: { src: ResetIcon, alt: '리셋아이콘' }
// };

export default function TextInput({ size, placeholder, disabled, className, error, type, id, value, onChange }) {
    const dispatch = useDispatch();
    const errorInfo = useSelector(selectErrorInfo);

    const [stateValue, setStateValue] = useState(value ?? '');

    const handleChange = e => {
        const newValue = e.target.value;
        setStateValue(newValue); // 내부 상태 업데이트
        if (onChange) {
            onChange(newValue); // 외부 핸들러 호출
        }
    };
    const handleReset = () => {
        setStateValue(''); // 입력 필드 초기화
        if (onChange) {
            onChange('');
        }
        if (errorInfo !== null) {
            dispatch(setError(null));
        }
    };

    return (
        <fieldset>
            <label htmlFor={id}></label>
            <TextField.Root
                size={size}
                id={id}
                value={stateValue}
                onChange={handleChange}
                placeholder={placeholder}
                disabled={disabled}
                className={className}
                type={type}
            >
                {stateValue && (
                    <TextField.Slot side="right">
                        <IconButton onClick={handleReset} variant="ghost" className="rt-variant-text" mr="var(--space-4)">
                            <Cross1Icon width={20} height={20} />
                            리셋
                        </IconButton>
                    </TextField.Slot>
                )}
            </TextField.Root>
            {/*{type === 'email' && (error || !stateValue) && (*/}
            {/*    <Flex align="center" gap="var(--space-1)" mt="var(--space-2)">*/}
            {/*        <img src={IMAGES.error.src} alt={IMAGES.error.alt} className="error-icon" />*/}
            {/*        <Text as="p" className="body3 error-msg">*/}
            {/*            등록되지 않은 이메일 주소입니다.*/}
            {/*        </Text>*/}
            {/*    </Flex>*/}
            {/*)}*/}
        </fieldset>
    );
}

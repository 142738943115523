import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';
import { Box, Container, Heading, Text } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import './style.scss';

const imgBanner = 'https://placehold.co/1920x610?text=1920x610';
const imgBannerMo = 'https://placehold.co/1280x714?text=1280x714';

const IMAGES = {
    imgBanner: { src: imgBanner, alt: '정보통신 산업진흥원이 함께 열어갑니다.' },
    imgBannerMo: { src: imgBannerMo, alt: '정보통신 산업진흥원이 함께 열어갑니다.' }
};

export const MainSlider = () => {
    const device = useSelector(selectDevice);

    //--- 메인슬라이드 ---//
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const [swiperRef, setSwiperRef] = useState(null);
    const [isRunning, setIsRunning] = useState(true);

    const play = () => {
        swiperRef.autoplay.start();
    };
    const pause = () => {
        swiperRef.autoplay.stop();
    };

    function isPause() {
        setIsRunning(!isRunning);
        if (!isRunning) {
            play();
        } else {
            pause();
        }
    }

    return (
        <>
            <Swiper
                className="main-slider"
                slidesPerView={'auto'}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                    type: 'fraction',
                    el: '.swiper-pagination',
                    renderFraction: function (currentClass, totalClass) {
                        return (
                            '<span class="' +
                            currentClass +
                            '"></span>' +
                            '<span class="of">/</span>' +
                            '<span class="' +
                            totalClass +
                            '"></span>'
                        );
                    },
                    formatFractionCurrent: function (number) {
                        return '0' + number;
                    },
                    formatFractionTotal: function (number) {
                        return '0' + number;
                    }
                }}
                navigation={{
                    prevEl: prevRef.current, //스와이퍼 외부에 컨트롤러 설정하기
                    nextEl: nextRef.current
                }}
                onBeforeInit={swiper => {
                    // 초기화 전에 네비게이션 버튼을 swiper에 할당합니다.
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                onSwiper={setSwiperRef}
            >
                <SwiperSlide className="slider-banner">
                    <div className="slider-image">
                        {device === 'pc' ? (
                            <img src={IMAGES.imgBanner.src} alt={IMAGES.imgBanner.alt} />
                        ) : (
                            <img src={IMAGES.imgBannerMo.src} alt={IMAGES.imgBannerMo.alt} />
                        )}
                    </div>
                    <Box className="slider-text">
                        <Container>
                            <Box className="slider-inner">
                                <Text as="p">ICT와 SW융합으로 만드는 새로운 대한민국</Text>
                                <Heading as="h2">정보통신 산업진흥원이 함께 열어갑니다.</Heading>
                                <button type="button" className="button-view">
                                    VIEW
                                </button>
                            </Box>
                        </Container>
                    </Box>
                </SwiperSlide>
                <SwiperSlide className="slider-banner">
                    <div className="slider-image">
                        {device === 'pc' ? (
                            <img src={IMAGES.imgBanner.src} alt={IMAGES.imgBanner.alt} />
                        ) : (
                            <img src={IMAGES.imgBannerMo.src} alt={IMAGES.imgBannerMo.alt} />
                        )}
                    </div>
                    <Box className="slider-text">
                        <Container>
                            <Box className="slider-inner">
                                <Text as="p">ICT와 SW융합으로 만드는 새로운 대한민국</Text>
                                <Heading as="h2">정보통신 산업진흥원이 함께 열어갑니다.</Heading>
                                <button type="button" className="button-view">
                                    VIEW
                                </button>
                            </Box>
                        </Container>
                    </Box>
                </SwiperSlide>
                <SwiperSlide className="slider-banner">
                    <div className="slider-image">
                        {device === 'pc' ? (
                            <img src={IMAGES.imgBanner.src} alt={IMAGES.imgBanner.alt} />
                        ) : (
                            <img src={IMAGES.imgBannerMo.src} alt={IMAGES.imgBannerMo.alt} />
                        )}
                    </div>
                    <Box className="slider-text">
                        <Container>
                            <Box className="slider-inner">
                                <Text as="p">ICT와 SW융합으로 만드는 새로운 대한민국</Text>
                                <Heading as="h2">정보통신 산업진흥원이 함께 열어갑니다.</Heading>
                                <button type="button" className="button-view">
                                    VIEW
                                </button>
                            </Box>
                        </Container>
                    </Box>
                </SwiperSlide>
                <SwiperSlide className="slider-banner">
                    <div className="slider-image">
                        {device === 'pc' ? (
                            <img src={IMAGES.imgBanner.src} alt={IMAGES.imgBanner.alt} />
                        ) : (
                            <img src={IMAGES.imgBannerMo.src} alt={IMAGES.imgBannerMo.alt} />
                        )}
                    </div>
                    <Box className="slider-text">
                        <Container>
                            <Box className="slider-inner">
                                <Text as="p">ICT와 SW융합으로 만드는 새로운 대한민국</Text>
                                <Heading as="h2">정보통신 산업진흥원이 함께 열어갑니다.</Heading>
                                <button type="button" className="button-view">
                                    VIEW
                                </button>
                            </Box>
                        </Container>
                    </Box>
                </SwiperSlide>
                <Box className="slider-utility">
                    <div className="swiper-pagination"></div>
                    <div className="slider-buttons">
                        <button type="button" className="slider-prev" ref={prevRef}>
                            Prev
                        </button>
                        <button type="button" className={!isRunning ? 'play' : 'pause'} onClick={isPause}>
                            {!isRunning ? '재생' : '정지'}
                        </button>
                        <button type="button" className="slider-next" ref={nextRef}>
                            Next
                        </button>
                    </div>
                </Box>
            </Swiper>
        </>
    );
};

export default MainSlider;

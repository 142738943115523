import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Text, Separator } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import './style.scss';

export const BannerSlider = () => {
    return (
        <>
            <Swiper
                className="banner-slider"
                modules={[Pagination]}
                pagination={{
                    el: '.swiper-pagination',
                    clickable: true
                }}
            >
                <SwiperSlide className="slider-banner type-dark bg-primary">
                    <Box className="slider-text">
                        <Box>
                            <Text as="p" className="title5">
                                거점·기업
                            </Text>
                            <Text as="p" className="body2">
                                지역 디지털 혁신 생태계 조성
                            </Text>
                        </Box>
                        <Separator orientation="horizontal" />
                        <ul>
                            <li>
                                <Link
                                    to="business/performance/detail?id=0"
                                    title="인공지능중심산업융합집적단지로 이동"
                                    state={'인공지능중심산업융합집적단지'}
                                >
                                    인공지능중심산업융합집적단지
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=1"
                                    state={'디지털 혁신거점 조성지원'}
                                    title="디지털 혁신거점 조성지원으로 이동"
                                >
                                    디지털 혁신거점 조성지원
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <img src={require('assets/images/pages/main/img-technology.svg').default} alt="거점·기업" />
                </SwiperSlide>
                <SwiperSlide className="slider-banner type-light">
                    <Box className="slider-text">
                        <Box>
                            <Text as="p" className="title5">
                                인적자원
                            </Text>
                            <Text as="p" className="body2">
                                지역혁신 선도 디지털 인재 양성체계 구축
                            </Text>
                        </Box>
                        <Separator orientation="horizontal" />
                        <ul>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=4"
                                    state={'스마트물류 기술 실증화'}
                                    title="스마트물류 기술 실증화로 이동"
                                >
                                    스마트물류 기술 실증화
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=5"
                                    state={'농식품 분야 메타버스 기반 기술 실증지원'}
                                    title="농식품 분야 메타버스 기반 기술 실증지원으로 이동"
                                >
                                    농식품 분야 메타버스 기반 기술 실증지원
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=6"
                                    state={'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용'}
                                    title="AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용으로 이동"
                                >
                                    AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <img src={require('../../assets/images/pages/main/img-resource.svg').default} alt="인적자원 이미지" />
                </SwiperSlide>
                <SwiperSlide className="slider-banner type-dark bg-navy">
                    <Box className="slider-text">
                        <Box>
                            <Text as="p" className="title5">
                                실증·보급
                            </Text>
                            <Text as="p" className="body2">
                                디지털 기반 지역경제 · 사회 혁신지원
                            </Text>
                        </Box>
                        <Separator orientation="horizontal" />
                        <ul>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=4"
                                    state={'스마트물류 기술 실증화'}
                                    title="스마트물류 기술 실증화로 이동"
                                >
                                    스마트물류 기술 실증화
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=5"
                                    state={'농식품 분야 메타버스 기반 기술 실증지원'}
                                    title="농식품 분야 메타버스 기반 기술 실증지원으로 이동"
                                >
                                    농식품 분야 메타버스 기반 기술 실증지원
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=6"
                                    state={'AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용'}
                                    title="AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용으로 이동"
                                >
                                    AI기반 어장 공간정보 빅데이터 플랫폼 구축 및 활용
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=7"
                                    state={'지역 디지털 기초체력 지원'}
                                    title="지역 디지털 기초체력 지원으로 이동"
                                >
                                    지역 디지털 기초체력 지원
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=8"
                                    state={'제조업 AI융합 기반 조성'}
                                    title="제조업 AI융합 기반 조성으로 이동"
                                >
                                    제조업 AI융합 기반 조성
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=9"
                                    state={'AI융합 지능형 농업 생태계 구축'}
                                    title="AI융합 지능형 농업 생태계 구축으로 이동"
                                >
                                    AI융합 지능형 농업 생태계 구축
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/business/performance/detail?id=10"
                                    state={'AI기반 중량화물이동체 물류플랫폼 실증'}
                                    title="AI기반 중량화물이동체 물류플랫폼 실증으로 이동"
                                >
                                    AI기반 중량화물이동체 물류플랫폼 실증
                                </Link>
                            </li>
                        </ul>
                    </Box>
                    <img src={require('../../assets/images/pages/main/img-dissemination.svg').default} alt="실증·보급 이미지" />
                </SwiperSlide>
                <Box className="slider-utility">
                    <div className="swiper-pagination"></div>
                </Box>
            </Swiper>
        </>
    );
};

export default BannerSlider;

import React, { useCallback, useEffect, useState } from 'react';
import { instance } from 'api/axios.instance';
import BoardImage from 'template/BoardImage';

export default function ProjectList() {
    const [projectRequestList, setProjectRequestList] = useState({ list: [], pagination: {} });
    const [loading, setLoading] = useState(true);
    const [fetchBody, setFetchBody] = useState({
        searchType: '',
        search: '',
        pageNo: 1,
        pageSize: 8,
        region: ''
    });
    const fetchProjectRequestList = useCallback(async () => {
        const response = await instance('/project/whole/list', {
            method: 'POST',
            data: fetchBody
        });
        setLoading(false);
        setProjectRequestList({ pagination: response.data.data.pagination, list: response.data.data.projects });
    }, [fetchBody]);

    useEffect(() => {
        fetchProjectRequestList();
    }, [fetchBody, fetchProjectRequestList]);
    return (
        <BoardImage
            pageTitle="프로젝트 목록"
            visualProps={{
                category: 'solution',
                title: '솔루션 뱅크',
                eng: 'Solution Bank'
            }}
            projectRequestList={projectRequestList}
            setFetchBody={setFetchBody}
            loading={loading}
        />
    );
}

import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { instance } from 'api/axios.instance';
import regions from 'util/region';

import { Box, Container, Flex, Heading, Text } from '@radix-ui/themes';
import SortByYear from 'components/SortByYear';
import Visual from 'components/Visual';
import BreadcrumbsBox from 'components/BreadcrumbsBox';
import PageTitle from 'components/PageTitle';
import { InfoLocalMap, InfoLocalMapMo } from 'components/LocalMap';

import NoData from 'components/NoData';

import { selectDevice, selectSelectedLocalYear } from 'store/slices/common';

import './style.scss';

export default function Survey() {
    const device = useSelector(selectDevice);
    const selectedLocalYear = useSelector(selectSelectedLocalYear);
    const year = parseInt(selectedLocalYear.replace('년', ''), 10);

    const [selectedRegion, setSelectedRegion] = useState('전체');
    const [selectedYear, setSelectedYear] = useState(year);
    const [triggerKey, setTriggerKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataResult, setDataResult] = useState({});

    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';

    /**
     * 지역 클릭시 이벤트
     * @param region
     * @param e
     */
    const handleClickMap = (region, e) => {
        if (e.key === 'Enter' || e.type === 'click') {
            setSelectedRegion(region);
        }
    };

    /**
     * 데이터 조회 함수
     * @returns {Promise<void>}
     */

    // const dataResult = {
    //     status: 200,
    //     message: '성공',
    //     data: {
    //         total: 10,
    //         posts: {
    //             id: 1,
    //             region: '전체',
    //             year: 2023,
    //             total_companies: 5000,
    //             it_manufacturing_companies: 1500,
    //             it_services_companies: 2000,
    //             sw_companies: 1500,
    //             total_employees: 30000,
    //             it_manufacturing_employees: 10000,
    //             it_services_employees: 12000,
    //             sw_employees: 8000,
    //             totalSale: 5000000000,
    //             itSale: 2000000000,
    //             it_services_sales: 1500000000,
    //             sw_sales: 1500000000,
    //             create_date: '2023-01-01T00:00:00Z'
    //         }
    //     }
    // };

    const fetchStatisticData = useCallback(async () => {
        const regionValue = regions.find(r => r.label === selectedRegion)?.value || 'ALL';
        const response = await instance(`/statistic/${selectedYear}/${regionValue}`, {
            method: 'GET'
        });

        setDataResult(response.data.data);
    }, [selectedRegion, selectedYear]);

    const handleClickYear = year => {
        console.log('year : ', year);

        setSelectedYear(year);
        setTriggerKey(prev => prev + 1);
    };

    function convertToNumber(stringNumber) {
        return Number(stringNumber.replace(/,/g, ''));
    }

    useEffect(() => {
        fetchStatisticData();
    }, [triggerKey, selectedRegion, fetchStatisticData]);

    return (
        <>
            <Visual category="business" title="사업" eng="Dreams Business" />
            <section className="survey">
                <Container>
                    <BreadcrumbsBox />
                    <PageTitle title="지역 디지털 산업 생태계 실태조사" />
                    <Flex
                        direction="column"
                        gap="var(--space-2)"
                        align="center"
                        justify="center"
                        mb={isMobile ? 'var(--space-16)' : 'var(--space-25)'}
                    >
                        <Text as="p" color="gray" className={isMobile ? 'sub-title2' : 'title6'}>
                            - 지역 디지털 생태계 경쟁력 강화 및 정책 추진을 위한 기초자료 제공
                        </Text>
                        <Text as="p" color="gray" className={isMobile ? 'sub-title2' : 'title6'}>
                            - 정책 수립, 기업 지원 전략 수립을 위한 디지털 산업 동향, 기업 현황 및 산업 인식 등 조사 • 분석
                        </Text>
                    </Flex>

                    <SortByYear path={'survey'} handleClickYear={handleClickYear} />
                    <Flex className="block-wrap">
                        {device === 'pc' ? (
                            <Box className="map-wrap">
                                <button
                                    type="button"
                                    className={'all ' + (selectedRegion === '전체' ? 'active' : '')}
                                    onClick={e => handleClickMap('전체', e)}
                                    onKeyDown={e => handleClickMap('전체', e)}
                                >
                                    전체
                                </button>
                                <InfoLocalMap handleClick={handleClickMap} selectedRegion={selectedRegion} />
                            </Box>
                        ) : (
                            <Box className="map-wrap">
                                <InfoLocalMapMo handleClick={handleClickMap} selectedRegion={selectedRegion} />
                            </Box>
                        )}
                        <Box className="text-wrap">
                            {dataResult && dataResult.totalCompany ? (
                                <>
                                    <Flex wrap="wrap" align="baseline" gap="var(--space-1) var(--space-4)">
                                        <Heading as="h4">{dataResult.region}</Heading>
                                        <p className="description">※ 매출액 및 종사자 수는 소수 첫째자리에서 반올림하여 표시함</p>
                                    </Flex>
                                    <Box className="card-chart type-building">
                                        <Box className="card-header">
                                            <p className="amount">
                                                기업 수<strong>{dataResult.totalCompany?.toLocaleString()}</strong>
                                            </p>
                                            <p className="unit">단위 : 개</p>
                                        </Box>
                                        <Box className="card-body">
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">IT 제조</p>
                                                <div className="building-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.itCompany) / convertToNumber(dataResult.totalCompany)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">건물이미지 차트</p>
                                                    </div>
                                                    {/*  차트 두줄 시 
                                            <div className="chart" style={{width: '20%'}}>
                                                <p className="cover">건물이미지 차트</p>
                                            </div>
                                            */}
                                                    <p className="result">{dataResult.itCompany?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">IT 서비스</p>
                                                <div className="building-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.serviceCompany) / convertToNumber(dataResult.totalCompany)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">건물이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.serviceCompany?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">SW</p>
                                                <div className="building-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.swCompany) / convertToNumber(dataResult.totalCompany)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">건물이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.swCompany?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box className="card-chart type-money">
                                        <Box className="card-header">
                                            <p className="amount">
                                                매출액
                                                <strong>{dataResult.totalSale?.toLocaleString()}</strong>
                                            </p>
                                            <p className="unit">단위 : 백만원</p>
                                        </Box>
                                        <Box className="card-body">
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">IT 제조</p>
                                                <div className="money-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.itSale) / convertToNumber(dataResult.totalSale)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">돈이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.itSale?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">IT 서비스</p>
                                                <div className="money-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.serviceSale) / convertToNumber(dataResult.totalSale)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">돈이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.serviceSale?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">SW</p>
                                                <div className="money-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.swSale) / convertToNumber(dataResult.totalSale)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">돈이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.swSale?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box className="card-chart type-person">
                                        <Box className="card-header">
                                            <p className="amount">
                                                종사자 수<strong>{dataResult.totalEmployee?.toLocaleString()}</strong>
                                            </p>
                                            <p className="unit">단위 : 명</p>
                                        </Box>
                                        <Box className="card-body">
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">IT 제조</p>
                                                <div className="person-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.itEmployee) / convertToNumber(dataResult.totalEmployee)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">사람이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.itEmployee?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">IT 서비스</p>
                                                <div className="person-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.serviceEmployee) / convertToNumber(dataResult.totalEmployee)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">사람이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.serviceEmployee?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                            <Flex className="chart-wrap">
                                                <p className="chart-label">SW</p>
                                                <div className="person-chart">
                                                    <div
                                                        className="chart"
                                                        style={{
                                                            width: `${(convertToNumber(dataResult.swEmployee) / convertToNumber(dataResult.totalEmployee)) * 100}%`
                                                        }}
                                                    >
                                                        <p className="cover">사람이미지 차트</p>
                                                    </div>
                                                    <p className="result">{dataResult.swEmployee?.toLocaleString()}</p>
                                                </div>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <NoData />
                            )}
                        </Box>
                    </Flex>
                </Container>
            </section>
        </>
    );
}

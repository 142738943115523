import React from 'react';
import { Box, Heading, Flex, Text } from '@radix-ui/themes';

import './style.scss';

export default function Report({ days, timeTable, isMobile }) {
    return (
        <>
            {/*<Box mt={!isMobile && 'var(--space-25)'} my={isMobile && 'var(--space-16)'} px={isMobile && 'var(--space-5)'}>*/}
            {/*    <Heading as={isMobile ? 'h4' : 'h3'} align={isMobile ? 'left' : 'center'} className={isMobile ? 'title4' : 'title3'}>*/}
            {/*        2024년 지역 디지털 산업 활성화 {!isMobile && <br />}*/}
            {/*        워크샵 및 성과보고회*/}
            {/*    </Heading>*/}
            {/*</Box>*/}
            <Flex
                direction="column"
                align="center"
                justify="center"
                gap="var(--space-2)"
                my={!isMobile && 'var(--space-10)'}
                mb={isMobile && 'var(--space-10)'}
            >
                <Box className="schedule-chip">
                    <Text as="p" align="center" className="title7">
                        {days[0].chip}
                    </Text>
                </Box>
                <Text as="p" align={isMobile ? 'left' : 'center'} className={isMobile ? 'title6' : 'title4'}>
                    {days[0].date}
                </Text>
            </Flex>

            <Flex direction="column" className="schedule-list">
                {timeTable[0].map((item, index) => (
                    <Flex
                        key={index}
                        direction={isMobile && 'column'}
                        align="start"
                        className={`schedule-item ${item.isBreak && 'break-time'}`}
                    >
                        <Text as="p" className="schedule-title">
                            {item.schedule}
                            {item.badge && (
                                <img src={require('assets/images/pages/bestPractices/award-badge.svg').default} alt="배지아이콘" />
                            )}
                        </Text>

                        <Box className={`schedule-contents ${item.badge && 'height-lg'}`}>
                            {item.list.map((el, i) => (
                                <Box key={i} className={`schedule-box ${!el.time && 'schedule-notime'} ${!el.title && 'schedule-nodata'}`}>
                                    <Box mb={isMobile && 'var(--space-2)'} className="schedule-time">
                                        <Text as="p" className="font-info">
                                            {el.time}
                                        </Text>
                                        <Flex align="center" mt={!isMobile && 'var(--space-1)'} gap="var(--space-1)">
                                            <img
                                                src={require('assets/images/pages/bestPractices/icon-time.svg').default}
                                                alt="시계 아이콘"
                                            />
                                            <Text as="p" className="body1">
                                                {el.duration}
                                            </Text>
                                        </Flex>
                                    </Box>
                                    {el.title && (
                                        <Box>
                                            <Text
                                                as="p"
                                                mb="var(--space-1)"
                                                className={`schedule-subject ${isMobile ? 'title7' : 'title6'}`}
                                            >
                                                {el.title}
                                            </Text>
                                            {el.detail && (
                                                <Box mb={isMobile ? 'var(--space-1)' : 'var(--space-5)'}>
                                                    {el.detail.map((obj, idx) => (
                                                        <Text key={idx} as="p" className={isMobile ? 'body3' : 'body1'}>
                                                            {obj[idx]}
                                                        </Text>
                                                    ))}
                                                </Box>
                                            )}
                                            <Text as="p" className={`font-basic ${isMobile ? 'sub-title2' : 'sub-title1'}`}>
                                                {el.desc}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Flex>
                ))}
            </Flex>

            <Flex
                direction="column"
                align="center"
                justify="center"
                gap="var(--space-2)"
                my={isMobile ? 'var(--space-8)' : 'var(--space-10)'}
            >
                <Box className="schedule-chip">
                    <Text as="p" align="center" className="title7">
                        {days[1].chip}
                    </Text>
                </Box>
                <Text as="p" align={isMobile ? 'left' : 'center'} className={isMobile ? 'title6' : 'title4'}>
                    {days[1].date}
                </Text>
            </Flex>
            <Flex direction="column" className="schedule-list">
                {timeTable[1].map((item, index) => (
                    <Flex
                        key={index}
                        direction={isMobile && 'column'}
                        align="start"
                        className={`schedule-item ${item.isBreak && 'break-time'}`}
                    >
                        <Text as="p" className="schedule-title">
                            {item.schedule}
                        </Text>

                        <Box className="schedule-contents">
                            {item.list.map((el, i) => (
                                <Box key={i} className={`schedule-box ${!el.time && 'schedule-notime'} ${!el.title && 'schedule-nodata'}`}>
                                    <Box mb={isMobile && 'var(--space-2)'} className="schedule-time">
                                        <Text as="p" className="font-info">
                                            {el.time}
                                        </Text>
                                        <Flex align="center" mt={!isMobile && 'var(--space-1)'} gap="var(--space-1)">
                                            <img
                                                src={require('assets/images/pages/bestPractices/icon-time.svg').default}
                                                alt="시계 아이콘"
                                            />
                                            <Text as="p" className="body1">
                                                {el.duration}
                                            </Text>
                                        </Flex>
                                    </Box>
                                    {el.title && (
                                        <Box>
                                            <Text
                                                as="p"
                                                mb="var(--space-1)"
                                                className={`schedule-subject ${isMobile ? 'title7' : 'title6'}`}
                                            >
                                                {el.title}
                                            </Text>
                                            {el.detail && (
                                                <Box mb={isMobile ? 'var(--space-1)' : 'var(--space-5)'}>
                                                    {el.detail.map((obj, idx) => (
                                                        <Text key={idx} as="p" className={isMobile ? 'body3' : 'body1'}>
                                                            {obj[idx]}
                                                        </Text>
                                                    ))}
                                                </Box>
                                            )}
                                            <Text as="p" className={`font-basic ${isMobile ? 'sub-title2' : 'sub-title1'}`}>
                                                {el.desc}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Flex>
                ))}
            </Flex>
        </>
    );
}

import Main from 'pages/Main';
import Login from 'pages/Login';
import AuthEmail from 'pages/Login/auth';
import Error from 'components/Error';

// 소개
import LocalDigitalIndustry from 'pages/Introduction/LocalDigitalIndustry'; // 지역 디지털 산업
import LocalDigitalInnovation from 'pages/Introduction/LocalDigitalInnovation'; // 지역디지털본부 소개
import AboutDreams from 'pages/Introduction/AboutDreams'; // Dreams란
// 정책
import ImportantPolicy from 'pages/Policy/ImportantPolicy'; // 중요정책
import PropulsionSystem from 'pages/Policy/PropulsionSystem'; // 추진체계
// 사업
import Performance from 'pages/Business/Performance'; // 사업별 성과 현황
import PerformanceDetail from 'pages/Business/Performance/Detail'; // 사업별 성과 현황 - 인포그래픽
import LocalBusiness from 'pages/Business/LocalBusiness'; // 지역별 사업 현황
import Survey from 'pages/Business/Survey'; // 실태조사
// 솔루션뱅크
import SolutionInfo from '../pages/Solution/Introduce'; // 사업 소개
import ProjectList from 'pages/Solution/ProjectList'; // 프로젝트 목록
import ProjectListDetail from 'pages/Solution/ProjectList/Detail'; // 프로젝트 목록 - 상세
// 우수사례
import PerformanceReport from 'pages/BestPractices/PerformanceReport'; // 성과보고회
import ManOfMerit from 'pages/BestPractices/ManOfMerit'; // 유공자
import Contest from 'pages/BestPractices/Contest';
import { Navigate, Route, useLocation } from 'react-router-dom'; // 공모전
// 공지사항
import Notice from 'pages/Performance/Notice'; // 공지사항
import NoticeDetail from 'pages/Performance/Notice/detail';
import VideoGallery from 'pages/Performance/VideoGallery'; // 우수영상관
import VideoGalleryDetail from 'pages/Performance/VideoGallery/detail.jsx';

const RouteList = [
    {
        path: '/',
        element: <Main />,
        private: false
    },
    {
        path: '/login',
        element: <Login />,
        private: false
    },
    {
        path: '/findPassword',
        element: <AuthEmail />,
        private: false
    },
    {
        path: '/findId',
        element: <AuthEmail />,
        private: false
    },
    {
        path: '/introduction/localDigitalIndustry',
        element: <LocalDigitalIndustry />,
        private: false
    },
    {
        path: '/introduction/localDigitalInnovation',
        element: <LocalDigitalInnovation />,
        private: false
    },
    {
        path: '/introduction/aboutDreams',
        element: <AboutDreams />,
        private: false
    },
    {
        path: '/policy/ImportantPolicy',
        element: <ImportantPolicy />,
        private: false
    },
    {
        path: '/policy/propulsionSystem',
        element: <PropulsionSystem />,
        private: false
    },
    // {
    //     path: '/business/regional',
    //     element: <LocalBusiness />,
    //     private: false
    // },
    {
        path: '/business/performance',
        element: <Performance />,
        private: false
    },
    {
        path: '/business/performance/detail',
        element: <PerformanceDetail />,
        private: false
    },
    {
        path: '/business/survey',
        element: <Survey />,
        private: false
    },
    {
        path: '/solution/info',
        element: <SolutionInfo />,
        private: false
    },
    {
        path: '/solution/projectList',
        element: <ProjectList />,
        private: false
    },
    {
        path: '/solution/projectList/detail/:id',
        element: <ProjectListDetail />,
        private: true
    },
    {
        path: '/bestPractices/performanceReport',
        element: <PerformanceReport />,
        private: false
    },
    {
        path: '/bestPractices/manOfMerit',
        element: <ManOfMerit />,
        private: false
    },
    {
        path: '/bestPractices/contest',
        element: <Contest />,
        private: false
    },
    {
        path: '/notice',
        element: <Notice />,
        private: false
    },
    {
        path: '/notice/detail/:id',
        element: <NoticeDetail />,
        private: false
    },
    {
        path: '/videoGallery',
        element: <VideoGallery />,
        private: false
    },
    {
        path: '/videoGallery/detail/:id',
        element: <VideoGalleryDetail />,
        private: false
    },
    {
        path: '*',
        element: <Error />,
        private: false,
        fullScreen: true
    }
];

const publicAuthRoutes = ['/login', '/findId', '/findPassword']; // 로그인 시 접근 불가능 path

const RedirectRoute = ({ isLogin, element }) => {
    return isLogin ? <Navigate to="/" replace /> : element;
};

const ProtectedRoute = ({ element, isLogin }) => {
    const location = useLocation();
    return isLogin ? element : <Navigate to="/login" replace state={{ from: location }} />;
};

export const generateRoutes = isLogin =>
    RouteList.map((route, index) => (
        <Route
            key={index}
            path={route.path}
            element={
                publicAuthRoutes.includes(route.path) ? (
                    <RedirectRoute element={route.element} isLogin={isLogin} />
                ) : route.private ? (
                    <ProtectedRoute element={route.element} isLogin={isLogin} />
                ) : (
                    route.element
                )
            }
        />
    ));

export default RouteList;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@radix-ui/themes';

import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, selectIsLogin } from 'store/slices/users';

const MenuItems = ({ items, activeItemId, onItemClick }) => (
    <div className="menu-items">
        {items.map(item => (
            <Link
                key={item.id}
                to={item.linkTo}
                className={activeItemId === item.id ? 'active' : ''}
                onClick={() => onItemClick(item.id)}
                title={item.label}
            >
                {item.label}
            </Link>
        ))}
    </div>
);

const LoggedInContent = ({ menuItems, handleLoginNavigateBtn }) => (
    <div className="menu-warp">
        <div className="menu-inner">
            <p className="member">회원</p>
            <MenuItems items={menuItems} />
        </div>
        <button type="button" onClick={handleLoginNavigateBtn}>
            LOGOUT
        </button>
    </div>
);

const NotLoggedInContent = ({ handleLoginNavigateBtn }) => (
    <div className="menu-warp">
        <div className="menu-inner">
            <p className="member">회원</p>
            <p className="info">로그인 후에 사용하실 수 있습니다.</p>
        </div>
        <button type="button" onClick={handleLoginNavigateBtn}>
            LOGIN
        </button>
    </div>
);

export default function QuickMenu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLogin = useSelector(selectIsLogin);

    const [isBtnExpanded, setBtnExpanded] = useState(false);

    const menuItems = [
        { id: 1, label: '지역 성과', linkTo: '/' },
        { id: 2, label: '나의 사업', linkTo: '/' },
        { id: 3, label: '나의 교육신청', linkTo: '/' },
        { id: 4, label: '나의 강의실', linkTo: '/' },
        { id: 5, label: '나의 시설신청', linkTo: '/' },
        { id: 6, label: '나의 장비신청', linkTo: '/' },
        { id: 7, label: '나의 참여게시판', linkTo: '/' },
        { id: 8, label: '나의 알림', linkTo: '/' }
    ];

    const handleLoginNavigateBtn = async () => {
        await setBtnExpanded(false);
        if (isLogin) {
            await dispatch(logoutUser());
        } else {
            navigate('/login');
        }
    };

    const handleBackToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <div className={`quick-menu ${isBtnExpanded ? 'open' : ''}`}>
                {/* {isBtnExpanded ? (
                    <>
                        {isLogin ? (
                            <LoggedInContent menuItems={menuItems} handleLoginNavigateBtn={handleLoginNavigateBtn} />
                        ) : (
                            <NotLoggedInContent handleLoginNavigateBtn={handleLoginNavigateBtn} />
                        )}
                    </>
                ) : (
                    <>
                        <button type="button" className="login" onClick={() => setBtnExpanded(!isBtnExpanded)}>
                            {isLogin ? 'LOGOUT' : 'LOGIN'}
                        </button>
                    </>
                )} */}
                <Box>
                    <button type="button" className="go-top" onClick={handleBackToTop}>
                        go top
                    </button>
                </Box>
            </div>
        </>
    );
}

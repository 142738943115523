import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

export default function Skipnav() {
    return (
        <>
            <div id="skipnav">
                <Link to="#contents">컨텐츠 바로가기</Link>
                <Link to="#menu">메뉴 바로가기</Link>
            </div>
        </>
    );
}
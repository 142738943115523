import React from 'react';
import { TextField, IconButton, Text } from '@radix-ui/themes';

import './style.scss';

export default function SearchBox() {
    return (
        <>
            <fieldset className="search-box">
                <Text as="label">
                    <TextField.Root placeholder="검색어를 입력해 주세요." color={'gray'} title="검색어 입력 창"></TextField.Root>
                </Text>
                <IconButton color={'gray'} className="rt-variant-text button-search">
                    <img src={require('assets/images/icon/icon-search.svg').default} alt="검색 아이콘" />
                </IconButton>
            </fieldset>
        </>
    );
}

import { Box, Button, Container, Flex } from '@radix-ui/themes';
import { Link, useNavigate } from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';

import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, selectIsLogin } from 'store/slices/users';

import SearchBox from '../SearchBox';
import { menuList } from './index';
import AccordionMenuItem from './AccordionMenuItem';

const MobileHeader = ({ isActive, setIsActive, toggleActive, currentPath }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogin = useSelector(selectIsLogin);

    const handleAuthAction = async () => {
        setIsActive(false);

        if (isLogin) {
            await dispatch(logoutUser());
        } else {
            navigate('/login');
        }
    };
    return (
        <header>
            <Flex align="center" className="header-inner">
                <h1 className="logo">
                    <Link to="/" title="메인으로 이동" onClick={() => setIsActive(false)}>
                        DREAMS
                    </Link>
                </h1>
                <Flex align="center" className="extra">
                    <Box className="search-wrap">
                        <SearchBox />
                    </Box>
                    <Button type="text" size="1" className={`site-map ${isActive ? 'active' : ''}`} onClick={toggleActive}>
                        사이트맵
                    </Button>
                </Flex>
            </Flex>
            <Container className="gnb-wrap">
                <Flex direction="column" justify="between">
                    <Accordion.Root type="single" collapsible className="gnb" defaultValue="item-1">
                        {menuList.map(menu => (
                            <AccordionMenuItem key={menu.id} menu={menu} currentPath={currentPath} setIsActive={setIsActive} />
                        ))}
                    </Accordion.Root>
                    <Box className="login-box">
                        {/* <SearchBox /> */}
                        <Button type="text" size="3" className="login" onClick={handleAuthAction}>
                            {isLogin ? 'LOGOUT' : 'LOGIN'}
                        </Button>
                    </Box>
                </Flex>
            </Container>
        </header>
    );
};

export default MobileHeader;

import React, { useState } from 'react';
import { Box, Container, Flex, Select, Separator, Dialog, Button } from '@radix-ui/themes';
import TermsDialog from 'components/Dialog/TermsDialog';

import { useSelector } from 'react-redux';
import { selectDevice } from 'store/slices/common';

import './style.scss';

export default function Footer() {
    const [temp, setTemp] = useState(0);
    const handleValueChange = value => {
        window.open(value, '_blank');
        setTemp(prev => prev + 1);
    };

    const deviceType = useSelector(selectDevice);
    const isMobile = deviceType === 'mobile';

    return (
        <footer>
            <Container>
                <Flex direction={isMobile && 'column'} align="center" justify="between" py="var(--space-5)">
                    <Flex align="center" className="terms-wrap">
                        <Box>
                            <Dialog.Root>
                                <Dialog.Trigger>
                                    <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                                        이용약관
                                    </Button>
                                </Dialog.Trigger>
                                <TermsDialog type="term" title="이용약관" />
                            </Dialog.Root>
                        </Box>
                        <Separator size="1" orientation="vertical" />
                        <Box>
                            <Dialog.Root>
                                <Dialog.Trigger>
                                    <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                                        개인정보처리방침
                                    </Button>
                                </Dialog.Trigger>
                                <TermsDialog type="personal" title="개인정보처리방침" />
                            </Dialog.Root>
                        </Box>
                        <Separator size="1" orientation="vertical" />
                        <Box>
                            <Dialog.Root>
                                <Dialog.Trigger>
                                    <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                                        저작권정책
                                    </Button>
                                </Dialog.Trigger>
                                <TermsDialog type="copyright" title="저작권정책" />
                            </Dialog.Root>
                        </Box>
                        <Separator size="1" orientation="vertical" />
                        <Box>
                            <Dialog.Root>
                                <Dialog.Trigger>
                                    <Button size="1" type="text" variant="ghost" color="gray" className="body2 font-basic">
                                        이메일무단수집거부
                                    </Button>
                                </Dialog.Trigger>
                                <TermsDialog type="email" title="이메일무단수집거부" />
                            </Dialog.Root>
                        </Box>
                    </Flex>
                    <Box className="family-site">
                        <Select.Root key={temp} size="3" onValueChange={value => handleValueChange(value)}>
                            <Select.Trigger placeholder="Family Site" />
                            <Select.Content position="popper" sideOffset="0">
                                <Select.Item value="https://www.msit.go.kr/" title="새창으로 과학기술 정보통신부로 이동">
                                    과학기술 정보통신부
                                </Select.Item>
                                <Select.Item value="https://www.nipa.kr/" title="새창으로 정보통신 산업진흥원으로 이동">
                                    정보통신 산업진흥원
                                </Select.Item>
                            </Select.Content>
                        </Select.Root>
                    </Box>
                </Flex>
                <Flex className="info-wrap">
                    <h2 className="logo">DREAMS</h2>
                    <Box className="info">
                        <p className="sub-title1">지역SW산업발전협의회</p>
                        <ul>
                            <li className="body2 address">
                                주소 : 서울특별시 마포구 월드컵북로 396, 14층 상암동 누리꿈 스퀘어 연구개발타워
                            </li>
                        </ul>
                        <p className="body3 copyright">Copyright © 2024 RSW All Right Reserved.</p>
                    </Box>
                </Flex>
            </Container>
        </footer>
    );
}
